"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const billing_1 = require("@actions/billing");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const BillingProvider_1 = require("@providers/BillingProvider");
const UserProvider_1 = require("@providers/UserProvider");
const Moment = require("moment");
const Plans = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    // Coupons
    const RANGE_MIN = 4;
    const RANGE_MAX = 15;
    const RANGE_STEP = 1;
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const subscription = _billing.subscription;
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [planType, setPlanType] = (0, react_1.useState)((_a = _billing.subscription.plan) === null || _a === void 0 ? void 0 : _a.type);
    const [quantity, setQuantity] = (0, react_1.useState)((((_b = _billing.subscription.plan) === null || _b === void 0 ? void 0 : _b.quantity) || 1) < 3
        ? 10
        : (_c = _billing.subscription.plan) === null || _c === void 0 ? void 0 : _c.quantity);
    const [previewData, setPreviewData] = (0, react_1.useState)(null);
    const getBaseFeatures = (isCurrent = false) => {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "checkmark", color: "green" }),
                " All product features included",
                ' '),
            React.createElement("span", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "checkmark", color: "green" }),
                " Access to",
                ' ',
                React.createElement(react_router_dom_1.Link, { to: { pathname: '/community' } }, "community Discord channel"),
                ' '),
            React.createElement("span", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "checkmark", color: "green" }),
                " Access to",
                ' ',
                React.createElement(react_router_dom_1.Link, { to: { pathname: '/community' } }, "join the beta testing program"),
                ' '),
            isCurrent && _billing.user.stripe_status == 'trialing' && (React.createElement("span", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "calendar", color: "teal" }),
                " Your free trial ends",
                ' ',
                Moment(_billing.trialEnd).fromNow(),
                " on",
                ' ',
                Moment(_billing.trialEnd).format(_user.getFormat('month_day_year')),
                "."))));
    };
    const _previewPlan = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (plan = null) {
        setIsLoading(true);
        const results = yield (0, billing_1.previewPlan)({
            plan_type: plan || planType,
            multiplier: quantity,
        });
        if (results.data.confirmation_required) {
            setPreviewData(results.data);
        }
        else {
            // Plan changed
            _billing.checkBilling(true);
        }
        setIsLoading(false);
    });
    const _updatePlan = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield (0, billing_1.updatePlan)({
            plan_type: planType,
            plan_currency: 'usd',
            multiplier: quantity,
        });
        setPreviewData(null);
        _billing.checkBilling(true);
        setIsLoading(false);
    });
    const switchPlan = (type) => __awaiter(void 0, void 0, void 0, function* () {
        if (_user.isAdmin) {
            setPlanType(type);
            yield _previewPlan(type);
        }
    });
    const nextPaymentIsToday = date => {
        return Math.abs(Moment(date).diff(Moment(), 'days')) < 1;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Card, { fluid: true },
            React.createElement(semantic_ui_react_1.Card.Content, { header: "Choose your Plan" }),
            !subscription ? (React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Loader, { inline: "centered", active: true, size: "tiny" }))) : (React.createElement(semantic_ui_react_1.Card.Content, null,
                !_billing.isPaused &&
                    ['legacy', 'friends', 'contributor'].indexOf((_d = _billing.subscription.plan) === null || _d === void 0 ? void 0 : _d.type) > -1 && (React.createElement("div", { className: `plan-details mb-1rem` },
                    React.createElement("div", { className: "sub-header" },
                        React.createElement("h3", { className: "flex--space-between" },
                            (0, format_1.toPrice)((_e = _billing.subscription.plan) === null || _e === void 0 ? void 0 : _e.amount, (_f = _billing.subscription.plan) === null || _f === void 0 ? void 0 : _f.currency),
                            ' ',
                            "per ", (_g = _billing.subscription.plan) === null || _g === void 0 ? void 0 :
                            _g.interval,
                            ' ',
                            _billing.subscription.plan.type == 'legacy' &&
                                `(discontinued)`)),
                    getBaseFeatures(true),
                    _billing.subscription.plan.type == 'legacy' ? (React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "exclamation triangle", color: "yellow" }),
                        " You are on a grandfathered plan. If you switch to another plan, you won't be able to go back to this price.")) : (React.createElement("span", null,
                        React.createElement(semantic_ui_react_1.Icon, { name: "heart", color: "red" }),
                        " You are on a free",
                        ' ',
                        _billing.subscription.plan.type == 'friends'
                            ? 'friends & family'
                            : 'contributor',
                        ' ',
                        "plan. We appreciate you!")),
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-1rem", disabled: true, size: "small", color: 'grey' }, "(Current plan)"))),
                React.createElement("div", { className: "plan-details-container" },
                    React.createElement("div", { className: `flex--column-space-between plan-details mb-1rem ${((_h = _billing.subscription.plan) === null || _h === void 0 ? void 0 : _h.type) == 'monthly' &&
                            !_billing.isPaused
                            ? 'selected'
                            : ''}` },
                        React.createElement("div", { className: "width-100" },
                            React.createElement("div", { className: "header-style" }, "Monthly"),
                            React.createElement("div", { className: "sub-header" },
                                React.createElement("h3", null,
                                    (0, format_1.toPrice)(10, _billing.user.stripe_plan_currency),
                                    " per month")),
                            getBaseFeatures(((_j = _billing.subscription.plan) === null || _j === void 0 ? void 0 : _j.type) == 'monthly')),
                        React.createElement("div", { className: "width-100" },
                            React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-1rem", size: "small", loading: isLoading, disabled: !_billing.isPaused &&
                                    (isLoading ||
                                        !_user.isAdmin ||
                                        ((_k = _billing.subscription.plan) === null || _k === void 0 ? void 0 : _k.type) == 'monthly'), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    yield switchPlan('monthly');
                                }), color: !_billing.isPaused &&
                                    ((_l = _billing.subscription.plan) === null || _l === void 0 ? void 0 : _l.type) == 'monthly'
                                    ? 'grey'
                                    : 'yellow' }, ((_m = _billing.subscription.plan) === null || _m === void 0 ? void 0 : _m.type) == 'monthly' &&
                                !_billing.isPaused
                                ? '(Current plan)'
                                : `${_billing.isPaused
                                    ? 'Unpause & select'
                                    : _billing.user.stripe_status == 'no subscription'
                                        ? 'Select'
                                        : 'Switch to'} this plan`))),
                    React.createElement("div", { className: `plan-details mb-1rem ${((_o = _billing.subscription.plan) === null || _o === void 0 ? void 0 : _o.type) == 'yearly' &&
                            ((_p = _billing.subscription.plan) === null || _p === void 0 ? void 0 : _p.quantity) == quantity &&
                            !_billing.isPaused
                            ? 'selected'
                            : ''}` },
                        React.createElement("div", { className: "header-style" },
                            React.createElement("div", null, "Annual"),
                            React.createElement("div", { className: "emph" }, "Set your own price!")),
                        React.createElement("div", { className: "sub-header" },
                            React.createElement("h3", null,
                                (0, format_1.toPrice)(quantity * 10, _billing.user.stripe_plan_currency),
                                ' ',
                                "per year"),
                            React.createElement("span", null,
                                (0, format_1.toPrice)((quantity * 10) / 12, _billing.user.stripe_plan_currency),
                                ' ',
                                "per month, billed annually")),
                        React.createElement("div", null,
                            React.createElement(semantic_ui_react_1.Input, { min: RANGE_MIN, max: RANGE_MAX, name: "duration", onChange: (e, data) => {
                                    setQuantity(parseInt(data.value));
                                }, step: RANGE_STEP, type: "range", value: quantity, list: "markers" }),
                            React.createElement("div", { style: {
                                    display: 'flex',
                                    position: 'relative',
                                    top: '-10px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    height: '20px',
                                    marginRight: '6px',
                                } },
                                React.createElement("div", { style: {
                                        position: 'absolute',
                                        left: `calc(${(100 / (RANGE_MAX - RANGE_MIN)) *
                                            (10 - RANGE_MIN)}% - 4.5px)`,
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "star", color: "yellow", fitted: true, size: "small" })),
                                React.createElement("div", { style: {
                                        position: 'absolute',
                                        left: `calc(${(100 / (RANGE_MAX - RANGE_MIN)) *
                                            (12 - RANGE_MIN)}% - 8px)`,
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "heart", color: "red", fitted: true, size: "small" })),
                                React.createElement("div", { style: {
                                        position: 'absolute',
                                        right: '0.5px',
                                    } },
                                    React.createElement(semantic_ui_react_1.Icon, { name: "gem", color: "blue", fitted: true, size: "small" })))),
                        getBaseFeatures(((_q = _billing.subscription.plan) === null || _q === void 0 ? void 0 : _q.type) == 'yearly' &&
                            ((_r = _billing.subscription.plan) === null || _r === void 0 ? void 0 : _r.quantity) == quantity),
                        quantity >= 10 && (React.createElement("span", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "star", color: "yellow" }),
                            " Thanks for helping us stay sustainable!")),
                        quantity >= 12 && (React.createElement("span", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "heart", color: "red" }),
                            " Receive a gift on your anniversary!",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { size: "tiny", inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", fitted: true, name: "question circle" }) }, "We'll snail mail you some awesome Lunch Money stationary/stickers!"))),
                        quantity >= 15 && (React.createElement("span", null,
                            React.createElement(semantic_ui_react_1.Icon, { name: "diamond", color: "blue" }),
                            " You rock! You'll get something extra special every year on your anniversary!",
                            ' ',
                            React.createElement(semantic_ui_react_1.Popup, { size: "tiny", inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", fitted: true, name: "question circle" }) }, "We'll snail mail you some awesome wearable Lunch Money goodies!"))),
                        React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mt-1rem", size: "small", loading: isLoading, disabled: isLoading ||
                                !_user.isAdmin ||
                                (!_billing.isPaused &&
                                    ((_s = _billing.subscription.plan) === null || _s === void 0 ? void 0 : _s.type) == 'yearly' &&
                                    ((_t = _billing.subscription.plan) === null || _t === void 0 ? void 0 : _t.quantity) == quantity), color: !_billing.isPaused &&
                                ((_u = _billing.subscription.plan) === null || _u === void 0 ? void 0 : _u.type) == 'yearly' &&
                                ((_v = _billing.subscription.plan) === null || _v === void 0 ? void 0 : _v.quantity) == quantity
                                ? 'grey'
                                : 'yellow', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                switchPlan('yearly');
                            }) }, ((_w = _billing.subscription.plan) === null || _w === void 0 ? void 0 : _w.type) == 'yearly' &&
                            ((_x = _billing.subscription.plan) === null || _x === void 0 ? void 0 : _x.quantity) == quantity &&
                            !_billing.isPaused
                            ? '(Current plan)'
                            : `${_billing.isPaused
                                ? 'Unpause & select'
                                : _billing.user.stripe_status == 'no subscription'
                                    ? 'Select'
                                    : 'Switch to'} this plan`)))))),
        React.createElement(semantic_ui_react_1.Modal, { open: !!previewData, closeOnEscape: false, closeOnDimmerClick: false, size: 'tiny', onClose: () => {
                setPreviewData(null);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null,
                "Switching to ",
                planType,
                " plan"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                !(previewData === null || previewData === void 0 ? void 0 : previewData.has_valid_payment) &&
                    (previewData === null || previewData === void 0 ? void 0 : previewData._summary.total) > 0 && (React.createElement(semantic_ui_react_1.Message, { error: true }, "You do not have a valid payment method associated to your account. You'll need to add a credit card before making changes to your plan!")),
                React.createElement("div", null,
                    React.createElement("p", null, "Upon confirmation, the following billing changes will be made to your account to reflect your new plan:"),
                    !!((_y = previewData === null || previewData === void 0 ? void 0 : previewData.credit) === null || _y === void 0 ? void 0 : _y.amount) && (React.createElement("div", { className: "mb-1rem" },
                        React.createElement("h4", { className: "mb-05rem" }, "Prorated credits"),
                        React.createElement("p", null,
                            "You will be credited",
                            ' ',
                            React.createElement("b", null, (0, format_1.toPrice)((previewData === null || previewData === void 0 ? void 0 : previewData.credit.amount) / -100, previewData === null || previewData === void 0 ? void 0 : previewData.credit.currency)),
                            ' ',
                            "for unused time between today and",
                            ' ',
                            Moment(previewData === null || previewData === void 0 ? void 0 : previewData.credit.period.end, 'X').format(_user.getFormat('month_day_year')),
                            "."))),
                    !!((_z = previewData === null || previewData === void 0 ? void 0 : previewData.debit) === null || _z === void 0 ? void 0 : _z.amount) && (React.createElement("div", { className: "mb-1rem" },
                        React.createElement("h4", { className: "mb-05rem" }, "New plan charges"),
                        React.createElement("p", null,
                            "You will be charged",
                            ' ',
                            React.createElement("b", null, (0, format_1.toPrice)((previewData === null || previewData === void 0 ? void 0 : previewData.debit.amount) / 100, previewData === null || previewData === void 0 ? void 0 : previewData.debit.currency)),
                            ' ',
                            "for a new ",
                            planType,
                            " subscription starting today and ending",
                            ' ',
                            Moment(previewData === null || previewData === void 0 ? void 0 : previewData.debit.period.end, 'X').format(_user.getFormat('month_day_year')),
                            "."),
                        React.createElement("p", null, "Your plan will auto-renew at the end of this period unless you cancel or pause your subscription."))),
                    React.createElement("div", { className: "mb-1rem" },
                        React.createElement("h4", { className: "mb-05rem" }, "Upcoming charges"),
                        React.createElement("p", null,
                            "You will be",
                            ' ',
                            (previewData === null || previewData === void 0 ? void 0 : previewData._summary.total) > 0 ? 'charged' : 'credited',
                            ' ',
                            React.createElement("b", null, (0, format_1.toPrice)(Math.abs((previewData === null || previewData === void 0 ? void 0 : previewData._summary.total) / 100), previewData === null || previewData === void 0 ? void 0 : previewData._summary.currency)),
                            ' ',
                            Moment(previewData === null || previewData === void 0 ? void 0 : previewData.next_payment_attempt).diff(Moment(), 'days') == 0
                                ? 'today'
                                : Moment(previewData === null || previewData === void 0 ? void 0 : previewData.next_payment_attempt).fromNow(),
                            ".",
                            ' ',
                            (previewData === null || previewData === void 0 ? void 0 : previewData._summary.total) > 0 &&
                                ((_0 = previewData === null || previewData === void 0 ? void 0 : previewData.balance) === null || _0 === void 0 ? void 0 : _0.amount) ? (React.createElement("span", null,
                                "You also have a credit balance of",
                                ' ',
                                React.createElement("b", null, (0, format_1.toPrice)(previewData.balance.amount, previewData.balance.currency)),
                                ' ',
                                "which will be applied towards this charge.")) : (React.createElement(React.Fragment, null)))),
                    planType == 'yearly' && (React.createElement(semantic_ui_react_1.Message, { info: true },
                        "Please note that your invoice will show a multiplier of",
                        ' ',
                        quantity,
                        " at a unit price of",
                        ' ',
                        (0, format_1.toPrice)(10, _billing.user.stripe_plan_currency),
                        " for the annual plan.")))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null, !(previewData === null || previewData === void 0 ? void 0 : previewData.has_valid_payment) &&
                (previewData === null || previewData === void 0 ? void 0 : previewData._summary.total) > 0 ? (React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, onClick: () => {
                    setPreviewData(null);
                } }, "Close and add payment information")) : (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Button, { loading: isLoading, disabled: isLoading, onClick: () => {
                        setPreviewData(null);
                    } }, "Cancel"),
                React.createElement(semantic_ui_react_1.Button, { color: "green", loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield _updatePlan();
                    }), content: nextPaymentIsToday(previewData === null || previewData === void 0 ? void 0 : previewData.next_payment_attempt)
                        ? `Switch plan and ${(previewData === null || previewData === void 0 ? void 0 : previewData._summary.total) > 0
                            ? 'pay'
                            : 'receive a credit of'} ${(0, format_1.toPrice)((previewData === null || previewData === void 0 ? void 0 : previewData.amount_due) / 100 ||
                            Math.abs((previewData === null || previewData === void 0 ? void 0 : previewData._summary.total) / 100), previewData === null || previewData === void 0 ? void 0 : previewData._summary.currency)}`
                        : `Yes, confirm subscription change` })))))));
};
exports.default = Plans;
