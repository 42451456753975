"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTooltip = exports.getNetWorthTooltip = void 0;
const Moment = require("moment");
const format_1 = require("@helpers/format");
/** Net worth */
const _getPercentChange = (prev, curr, primaryCurrency) => {
    // if (prev == 0 && curr == 0) {
    //   return `+$0.00%`
    // }
    if (prev == curr) {
        return 0;
    }
    else if (prev == null || curr == null || prev == 0) {
        return null;
    }
    else if (prev * curr < 0) {
        return null; // No percentage changes between negative and positives
    }
    const flipSign = curr < 0 && prev < 0;
    let percentChangeAsset = (0, format_1.toPercentage)((curr - prev) / prev);
    if (flipSign) {
        if (percentChangeAsset[0] != '-') {
            percentChangeAsset = `-${percentChangeAsset}`;
        }
        else {
            percentChangeAsset = `+${percentChangeAsset.slice(1)}`;
        }
    }
    else {
        if (percentChangeAsset[0] != '-') {
            percentChangeAsset = `+${percentChangeAsset}`;
        }
    }
    return `${percentChangeAsset}, ${(0, format_1.toPrice)(curr - prev, primaryCurrency)}`;
};
const _getTooltipLine = (labelName, amount, percent, color, classNames, reverseColors = false) => {
    let tooltipColor = '';
    if (percent) {
        if (percent[0] == '-') {
            // A drop
            if (reverseColors) {
                tooltipColor = 'color--green';
            }
            else {
                tooltipColor = 'color--red';
            }
        }
        else {
            // A rise
            if (reverseColors) {
                tooltipColor = 'color--red';
            }
            else {
                tooltipColor = 'color--green';
            }
        }
    }
    return `<div class="flex--space-between tooltip-data-row ${classNames}">
            <div class="flex--align-center">
              ${color
        ? `<span class="circle-color"
              style="background-color:${color}"></span>`
        : ``}
              <span class="tooltip-label">${labelName}</span>
            </div>
            <div class="monospace number no-wrap">${percent == 0
        ? '<span class="color--grey">(+0.0%)</span>'
        : typeof percent !== 'undefined' && percent !== null
            ? `<span class="${tooltipColor}">(${percent})</span>`
            : ''} ${amount}
            </div>
          </div>`;
};
const ASSET_COLORS = [
    '#6BC1EF',
    '#1172A6',
    '#B6E0F7',
    '#0E98BE',
    '#11B5E4',
    '#0A3F5C',
];
const LIABILITY_COLORS = [
    '#FB9DA4',
    '#E8081B',
    '#FEE3E5',
    '#F95664',
    '#FDC4C9',
    '#D30819',
];
const getNetWorthTooltip = (chartContext, chartRef, tooltip, data, prevData, primaryCurrency, sections, allAccounts, { hasHiddenTypes, chartBreakdown, percentView, hideAccountBreakdown, netWorthColor = null, }, monthYearFormat) => {
    if (!chartRef)
        return;
    if (!tooltip)
        return;
    if (!tooltip.dataPoints || tooltip.dataPoints.length == 0) {
        // outside of range
        return;
    }
    const date = Moment(tooltip.title[0], 'MMM YYYY').format(monthYearFormat);
    if (chartBreakdown == 'account') {
        return _getByAccountTooltip(chartContext, chartRef, date, data, prevData, allAccounts, percentView, tooltip, primaryCurrency);
    }
    if (data.net_worth == null)
        return;
    let percentChange = null;
    let content = '';
    let currentTotal = 0;
    let prevTotal = 0;
    let grandTotal = 0;
    let prevGrandTotal = 0;
    sections.forEach(section => {
        var _a, _b;
        if (data.hasOwnProperty(section.header)) {
            // Calculate percentage change
            if (percentView == 'pct-change') {
                percentChange = _getPercentChange((_a = prevData === null || prevData === void 0 ? void 0 : prevData[section.header]) === null || _a === void 0 ? void 0 : _a.total, (_b = data === null || data === void 0 ? void 0 : data[section.header]) === null || _b === void 0 ? void 0 : _b.total, primaryCurrency);
            }
            let lines = [];
            if (!hideAccountBreakdown) {
                // Get the grand total first
                const sectionTotal = section.subtypes.reduce((acc, cur) => {
                    if (data[section.header]['by_type'].hasOwnProperty(cur)) {
                        return acc + data[section.header]['by_type'][cur].total;
                    }
                    else {
                        return acc;
                    }
                }, 0);
                const prevSectionTotal = section.subtypes.reduce((acc, cur) => {
                    if (prevData &&
                        prevData[section.header]['by_type'].hasOwnProperty(cur)) {
                        return acc + prevData[section.header]['by_type'][cur].total;
                    }
                    else {
                        return acc;
                    }
                }, 0);
                if (section.header == 'assets') {
                    grandTotal += sectionTotal;
                    prevGrandTotal += prevSectionTotal;
                }
                else {
                    grandTotal -= sectionTotal;
                    prevGrandTotal -= prevSectionTotal;
                }
                // Individual lines
                section.subtypes.forEach((typeName, index) => {
                    if (data[section.header]['by_type'].hasOwnProperty(typeName)) {
                        const currentPeriod = data[section.header]['by_type'][typeName];
                        const prevPeriod = prevData && prevData[section.header]['by_type'][typeName];
                        currentTotal += currentPeriod.total;
                        prevTotal += (prevPeriod === null || prevPeriod === void 0 ? void 0 : prevPeriod.total) || 0;
                        if (percentView == 'pct-change') {
                            // Calculate percentage change for individual types
                            try {
                                percentChange = _getPercentChange((prevPeriod === null || prevPeriod === void 0 ? void 0 : prevPeriod.total) || null, currentPeriod.total, primaryCurrency);
                            }
                            catch (e) {
                                percentChange = null;
                            }
                        }
                        else if (percentView == 'pct-total') {
                            // Get breakdown of current type
                            percentChange = (0, format_1.toPercentage)(currentPeriod.total / sectionTotal, 1);
                        }
                        else {
                            percentChange = null;
                        }
                        lines.push(_getTooltipLine((0, format_1.capitalize)(typeName), (0, format_1.toPrice)(currentPeriod.total, primaryCurrency), percentChange, section.colors[index % section.colors.length], undefined, section.shouldReverseColors));
                    }
                });
                // Header (Liability or Assets)
                content += _getTooltipLine((0, format_1.capitalize)(section.header), (0, format_1.toPrice)(sectionTotal, primaryCurrency), percentView == 'pct-change'
                    ? _getPercentChange(prevSectionTotal || null, sectionTotal, primaryCurrency)
                    : null, null, hideAccountBreakdown ? '' : 'highlight-title', section.shouldReverseColors);
                // Body
                content += lines.reverse().join('');
                // Reset
                currentTotal = 0;
                prevTotal = 0;
                percentChange = null;
            }
        }
    });
    // Calculate percentage change
    if (percentView == 'pct-change') {
        percentChange = _getPercentChange(prevGrandTotal, grandTotal, primaryCurrency);
    }
    else {
        percentChange = null;
    }
    // Grand total
    const tooltipEl = document.getElementById(`net-worth-tool-tip`);
    tooltipEl.innerHTML = `<div class="padded">
    <div class='flex--space-between header'>
      <div>${date}</div>
    </div>
    ${content}
    ${_getTooltipLine(hasHiddenTypes ? 'Total' : 'Net Worth', (0, format_1.toPrice)(grandTotal, data.currency), percentChange, hasHiddenTypes ? null : netWorthColor || '#A9DD2F', 'overline')}
  </div>`;
    tooltipEl.style.display = 'block';
    // Or to the right of the bar...
    let canvasHeight;
    try {
        canvasHeight = chartContext.canvas.style.height.match(/\d*/g)[0];
    }
    catch (e) {
        canvasHeight = '320';
    }
    var positionY = chartContext.canvas.offsetTop;
    var positionX = chartContext.canvas.offsetLeft;
    const defaultLeft = positionX + tooltip.caretX + 40;
    const canvasWidth = chartContext.canvas.style.width;
    const tooltipWidth = tooltipEl.scrollWidth;
    // Display, position, and set styles for font
    tooltipEl.style.display = 'block';
    if (defaultLeft + tooltipWidth + 100 > parseInt(canvasWidth)) {
        tooltipEl.style.left = positionX + tooltip.caretX - tooltipWidth - 40 + 'px';
    }
    else {
        tooltipEl.style.left = defaultLeft + 'px';
    }
    tooltipEl.style.top =
        positionY + tooltip.caretY - tooltipEl.clientHeight / 2 - 10 + 'px';
};
exports.getNetWorthTooltip = getNetWorthTooltip;
const _getByAccountTooltip = (chartContext, chartRef, date, data, prevData, allAccounts, percentView, tooltip, primaryCurrency) => {
    var _a;
    let lines = [];
    (_a = tooltip.dataPoints) === null || _a === void 0 ? void 0 : _a.forEach((point, index) => {
        const timeIndex = point.index;
        const accountIndex = point.datasetIndex;
        if (accountIndex == allAccounts.length) {
            let percentChange = null;
            if (prevData && percentView == 'pct-change') {
                if ((prevData === null || prevData === void 0 ? void 0 : prevData.net_worth) !== null) {
                    percentChange = _getPercentChange(prevData.net_worth, data.net_worth, primaryCurrency);
                }
            }
            lines.push(_getTooltipLine('Net Worth', (0, format_1.toPrice)(data.net_worth, data.currency), percentChange, '#A9DD2F', index == 0 ? 'overline' : ''));
        }
        else {
            const account = allAccounts[accountIndex];
            const balance = data[account.is_asset ? 'assets' : 'liabilities'].by_account[account._id];
            let percentChange = null;
            if (prevData && percentView == 'pct-change') {
                const prevBalance = prevData[account.is_asset ? 'assets' : 'liabilities'].by_account[account._id];
                if (prevBalance && (prevBalance === null || prevBalance === void 0 ? void 0 : prevBalance.to_base)) {
                    percentChange = _getPercentChange(prevBalance.to_base, balance.to_base, primaryCurrency);
                }
            }
            const colors = account.is_asset ? ASSET_COLORS : LIABILITY_COLORS;
            lines.push(_getTooltipLine(account.name, (0, format_1.toPrice)(balance.balance, balance.currency), percentChange, colors[accountIndex % colors.length], index == 0 ? 'overline' : '', !account.is_asset));
        }
    });
    const tooltipEl = document.getElementById(`net-worth-tool-tip`);
    tooltipEl.innerHTML = `<div class="padded">
    <div class='flex--space-between header'>
      <div>${date}</div>
    </div>
    ${lines.join('')}
  </div>`;
    tooltipEl.style.display = 'block';
    // Or to the right of the bar...
    let canvasHeight;
    try {
        canvasHeight = chartContext.canvas.style.height.match(/\d*/g)[0];
    }
    catch (e) {
        canvasHeight = '320';
    }
    // tooltipEl.style.left = positionX + tooltip.caretX + 40 + 'px'
    // tooltipEl.style.top =
    //   positionY + tooltip.caretY - tooltipEl.clientHeight / 2 - 10 + 'px'
    var positionY = chartContext.canvas.offsetTop;
    var positionX = chartContext.canvas.offsetLeft;
    const defaultLeft = positionX + tooltip.caretX + 40;
    const canvasWidth = chartContext.canvas.style.width;
    const tooltipWidth = tooltipEl.scrollWidth;
    // Display, position, and set styles for font
    tooltipEl.style.display = 'block';
    if (defaultLeft + tooltipWidth + 100 > parseInt(canvasWidth)) {
        tooltipEl.style.left = positionX + tooltip.caretX - tooltipWidth - 40 + 'px';
    }
    else {
        tooltipEl.style.left = defaultLeft + 'px';
    }
    tooltipEl.style.top =
        positionY + tooltip.caretY - tooltipEl.clientHeight / 2 - 10 + 'px';
};
/** Query tool - pie chart */
const getTooltip = (context, format) => {
    var _a;
    if (!context)
        return;
    if (!context.tooltip) {
        return;
    }
    if (!context.tooltip.dataPoints || context.tooltip.dataPoints.length == 0) {
        // outside of range
        return;
    }
    const firstBar = context.tooltip.dataPoints[0];
    let content = '';
    (_a = context.tooltip.body) === null || _a === void 0 ? void 0 : _a.forEach((body, index) => {
        content += body.lines;
    });
    const tooltipEl = document.getElementById(`query-tool-tip`);
    if (firstBar.xLabel) {
        tooltipEl.innerHTML = `<p class='header flex--space-between-flex-start'>${Moment(firstBar.label, 'YYYY-MM-DD').format(format)}
    </p><div class="tooltip-scrollable">${content}</div>`;
    }
    else if (format) {
        // Line chart
        tooltipEl.innerHTML = `<p class='header flex--space-between-flex-start'>${Moment(context.tooltip.title[0]).format(format)}</p>
    <div class="tooltip-scrollable">${content}</div>`;
    }
    else {
        // pie chart
        tooltipEl.innerHTML = `<p class='header flex--space-between-flex-start'>
    ${context.tooltip.title[0]}</p>
    <div class="tooltip-scrollable">${content}</div>`;
    }
    tooltipEl.style.display = 'block';
    // Or to the right of the bar...
    let canvasHeight;
    try {
        canvasHeight = context.chart.canvas.style.height.match(/\d*/g)[0];
    }
    catch (e) {
        canvasHeight = '320';
    }
    var positionY = context.chart.canvas.offsetTop;
    var positionX = context.chart.canvas.offsetLeft;
    const verticalLine = document.getElementById(`query-vertical`);
    if (verticalLine) {
        verticalLine.style.height = Number(canvasHeight) - 20 + 'px';
        verticalLine.style.left = positionX + context.tooltip.caretX - 2 + 'px';
        verticalLine.style.top = positionY + 'px';
    }
    // Display, position, and set styles for font
    tooltipEl.style.display = 'block';
    tooltipEl.style.left = positionX + context.tooltip.caretX + 10 + 'px';
    tooltipEl.style.top =
        positionY + context.tooltip.caretY - tooltipEl.clientHeight / 2 - 10 + 'px';
};
exports.getTooltip = getTooltip;
