"use strict";
/**
 *  Admin.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const EditableDate_1 = require("@components/elements/EditableDate");
const UserInfo_1 = require("@components/Admin/UserInfo");
const format_1 = require("@helpers/format");
const CopyCommand_1 = require("@components/elements/CopyCommand");
const admin_1 = require("@actions/admin");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const react_json_view_1 = require("@microlink/react-json-view");
const Admin = ({ _process, _showToast }) => {
    var _a, _b, _c, _d, _e;
    // Results
    const [plaidError, setPlaidError] = (0, react_1.useState)(null);
    const [errorMessage, setErrorMessage] = (0, react_1.useState)(null);
    const [user, setUser] = (0, react_1.useState)(null);
    const [accounts, setAccounts] = (0, react_1.useState)(null);
    const [errorLogs, setErrorLogs] = (0, react_1.useState)(null);
    const [plaidLogs, setPlaidLogs] = (0, react_1.useState)(null);
    const [connections, setConnections] = (0, react_1.useState)(null);
    const [plaidAccounts, setPlaidAccounts] = (0, react_1.useState)(null);
    const [selectedPlaidAccount, setSelectedPlaidAccount] = (0, react_1.useState)(null);
    const [transactions, setTransactions] = (0, react_1.useState)(null);
    // State
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [viewItem, setViewItem] = (0, react_1.useState)('user-details');
    // Inputs
    const [input, setInput] = (0, react_1.useState)('');
    const [inputType, setInputType] = (0, react_1.useState)('auto-detect');
    const [startDate, setStartDate] = (0, react_1.useState)(Moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD'));
    const [endDate, setEndDate] = (0, react_1.useState)(Moment().format('YYYY-MM-DD'));
    // Metadata
    const [showMetadata, setShowMetadata] = (0, react_1.useState)(null);
    const toReadableDateSpan = date => {
        if (!date) {
            return React.createElement("span", { className: "color--red" }, "Never");
        }
        else {
            return (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top center", trigger: React.createElement("span", null, Moment(date).fromNow()) }, Moment(date).format('MMMM D, YYYY [at] h:mm a')));
        }
    };
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Admin Portal - Lunch Money';
    }, []);
    const getData = (obj) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const results = yield _process(admin_1.getUserData)(Object.assign({ start_date: startDate, end_date: endDate, exclude: [], type: inputType }, obj));
        // Set any error messages
        if (results.data.hasOwnProperty('plaid_error')) {
            setPlaidError(results.data.plaid_error);
        }
        else {
            setPlaidError(null);
        }
        if (results.data.hasOwnProperty('error_message')) {
            setErrorMessage(results.data.error_message);
            if (results.data.error_message.indexOf('No objects found') == 0) {
                // Reset all data
                setUser(null);
                setAccounts(null);
                setErrorLogs(null);
                setPlaidLogs(null);
                setConnections(null);
                setPlaidAccounts(null);
                setTransactions(null);
                setSelectedPlaidAccount(null);
                setViewItem('user-details');
            }
        }
        else {
            setErrorMessage(null);
        }
        // Override user, if any
        if (results.data.hasOwnProperty('user')) {
            setUser(results.data.user);
            setViewItem('user-details');
        }
        // Override user budgeting accounts, if any
        if (results.data.hasOwnProperty('accounts')) {
            setAccounts(results.data.accounts);
        }
        // Override error logs, if any
        if (results.data.hasOwnProperty('error_logs')) {
            setErrorLogs(results.data.error_logs);
        }
        if (results.data.hasOwnProperty('plaid_logs')) {
            setPlaidLogs(results.data.plaid_logs);
        }
        // Show connections, if any
        if (results.data.hasOwnProperty('connections')) {
            setConnections(results.data.connections.map(connectionGroup => {
                const connection = connectionGroup[0];
                return {
                    institution_name: connection.institution_name,
                    institution_external_id: connection.institution_external_id,
                    access_token: connection.access_token,
                    account_id: connection.account_id,
                    num_active: connectionGroup.filter(o => o.status == 'active')
                        .length,
                    num_relink: connectionGroup.filter(o => o.status == 'relink')
                        .length,
                    num_revoked: connectionGroup.filter(o => o.status == 'revoked')
                        .length,
                    num_inactive: connectionGroup.filter(o => o.status == 'inactive')
                        .length,
                    num_total: connectionGroup.length,
                };
            }));
            setViewItem('connections');
        }
        // Override plaid_accounts (and connections), if any
        if (results.data.hasOwnProperty('plaid_accounts')) {
            setPlaidAccounts(results.data.plaid_accounts);
            setViewItem('accounts');
        }
        // Override transactions, if any
        if (results.data.hasOwnProperty('transactions')) {
            setTransactions(results.data.transactions);
            if (!results.data.hasOwnProperty('plaid_error') ||
                (results.data.hasOwnProperty('transactions') &&
                    results.data.transactions.length > 0)) {
                setViewItem('transactions');
            }
        }
        // Override selected plaid account, if any
        if (results.data.plaid_account) {
            setSelectedPlaidAccount(results.data.plaid_account);
        }
        setIsLoading(false);
    });
    const showDifference = (plaid, user) => {
        return plaid !== user ? (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "university", fitted: true }),
                ' ',
                plaid || React.createElement("span", { className: "color--red" }, "Missing")),
            React.createElement("p", null,
                React.createElement(semantic_ui_react_1.Icon, { name: "user", fitted: true }),
                ' ',
                user || React.createElement("span", { className: "color--red" }, "Missing")))) : (plaid);
    };
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(ContainerHeader_1.default, { title: "Admin Portal" }),
        React.createElement("div", { id: "g-admin-container" },
            React.createElement(semantic_ui_react_1.Card, { className: "width-100", fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { inline: true, className: "mb-0" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(semantic_ui_react_1.Dropdown, { fluid: true, selection: true, value: inputType, onChange: (e, { value }) => setInputType(value), options: [
                                        {
                                            key: 'auto',
                                            value: 'auto-detect',
                                            text: 'Auto-detect',
                                        },
                                        {
                                            key: 'user_id',
                                            value: 'user_id',
                                            text: 'user_id',
                                        },
                                        {
                                            key: 'plaid_account_id',
                                            value: 'plaid_account_id',
                                            text: 'plaid_account_id',
                                        },
                                    ] })),
                            React.createElement(semantic_ui_react_1.Form.Field, { id: "input-field" },
                                React.createElement(semantic_ui_react_1.Input, { type: "text", className: "width-100", autoFocus: true, placeholder: "plaid_account_id, user_id, email, item_id, account_id, access_token", value: input, onChange: (e, { value }) => setInput(value) })),
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(EditableDate_1.default, { placeholder: "From (optional)", identifier: "from-query", firstValue: startDate, state: 'Editing', yearPicker: true, location: 'modal', format: 'month_day_year', onSave: start_date => {
                                        setStartDate(start_date);
                                    }, showError: startDate && startDate > endDate })),
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement(EditableDate_1.default, { placeholder: "To (optional)", identifier: "to-query", firstValue: endDate, state: 'Editing', yearPicker: true, location: 'modal', format: 'month_day_year', onSave: end_date => {
                                        setEndDate(end_date);
                                    }, showError: endDate && endDate < startDate })),
                            React.createElement(semantic_ui_react_1.Button, { color: "orange", content: "Get data", icon: "right arrow", className: "no-wrap", labelPosition: "right", loading: isLoading, disabled: isLoading || (input === null || input === void 0 ? void 0 : input.length) == 0 || !input, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    setPlaidError(null);
                                    setErrorMessage(null);
                                    setConnections(null);
                                    setPlaidAccounts(null);
                                    setSelectedPlaidAccount(null);
                                    setTransactions(null);
                                    yield getData({ value: input });
                                }) }),
                            (plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.has_active) && (React.createElement(semantic_ui_react_1.Button, { content: "Resync", icon: "sync", labelPosition: "right", loading: isLoading, disabled: isLoading, color: "green", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    setIsLoading(true);
                                    // Update user
                                    const results = yield _process(admin_1.pullTransactions)({
                                        access_token: plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.access_token,
                                        trigger_refresh: false, // not used
                                        start_date: startDate,
                                        end_date: endDate,
                                    });
                                    if (!results.error) {
                                        _showToast({
                                            autoDismissTimeout: 5000,
                                            type: 'success',
                                            message: 'Successfully triggered resync. This page will auto-refresh in 5 seconds.',
                                        });
                                        setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                                            if (selectedPlaidAccount === null || selectedPlaidAccount === void 0 ? void 0 : selectedPlaidAccount.id) {
                                                setInput(selectedPlaidAccount === null || selectedPlaidAccount === void 0 ? void 0 : selectedPlaidAccount.id);
                                                setInputType('plaid_account_id');
                                                yield getData({
                                                    value: selectedPlaidAccount === null || selectedPlaidAccount === void 0 ? void 0 : selectedPlaidAccount.id,
                                                    type: 'plaid_account_id',
                                                });
                                            }
                                            else {
                                                yield getData({
                                                    value: plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.access_token,
                                                });
                                            }
                                        }), 5000);
                                    }
                                    setIsLoading(false);
                                }) }))))))),
        (user === null || user === void 0 ? void 0 : user.scheduled_for_deletion) && (React.createElement(semantic_ui_react_1.Message, { warning: true },
            "This user is scheduled for deletion on",
            ' ',
            Moment(user === null || user === void 0 ? void 0 : user.scheduled_for_deletion).format('MMM D, YYYY'),
            ".")),
        React.createElement("div", { className: "mt-1rem", id: "g-admin-menu" },
            React.createElement(semantic_ui_react_1.Menu, null,
                React.createElement(semantic_ui_react_1.MenuItem, { disabled: !user && !accounts, name: "user-details", active: viewItem === 'user-details', onClick: () => {
                        setViewItem('user-details');
                    } }, "User Details"),
                React.createElement(semantic_ui_react_1.MenuItem, { disabled: !errorLogs || (errorLogs === null || errorLogs === void 0 ? void 0 : errorLogs.length) == 0, name: "errors", active: viewItem === 'errors', onClick: () => {
                        setViewItem('errors');
                    } },
                    "Errors",
                    !!plaidLogs ? ` (${plaidLogs.length})` : ''),
                React.createElement(semantic_ui_react_1.MenuItem, { disabled: !connections || (connections === null || connections === void 0 ? void 0 : connections.length) == 0, name: "connections", active: viewItem === 'connections', onClick: () => {
                        setViewItem('connections');
                    } },
                    "Connections",
                    !!connections ? ` (${connections.length})` : ''),
                React.createElement(semantic_ui_react_1.MenuItem, { disabled: !plaidAccounts || ((_a = plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.accounts) === null || _a === void 0 ? void 0 : _a.length) == 0, name: "accounts", active: viewItem === 'accounts', onClick: () => {
                        setViewItem('accounts');
                    } },
                    "Accounts",
                    ((_b = plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.accounts) === null || _b === void 0 ? void 0 : _b.length) > 0
                        ? `: ${plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.institution_name} (${(_c = plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.accounts) === null || _c === void 0 ? void 0 : _c.length})`
                        : ''),
                React.createElement(semantic_ui_react_1.MenuItem, { disabled: transactions == null, name: "transactions", active: viewItem === 'transactions', onClick: () => {
                        setViewItem('transactions');
                    } },
                    "Transactions",
                    selectedPlaidAccount && (transactions === null || transactions === void 0 ? void 0 : transactions.length) > 0
                        ? `: ${selectedPlaidAccount.display_name ||
                            selectedPlaidAccount.name} (${transactions.length})`
                        : ''))),
        (plaidError === null || plaidError === void 0 ? void 0 : plaidError.error_code) && (React.createElement(semantic_ui_react_1.Message, { negative: true },
            React.createElement(semantic_ui_react_1.Message.Header, null, "Plaid returned an error with this request!"),
            React.createElement(semantic_ui_react_1.Message.Content, { className: "mt-1rem" },
                React.createElement("p", null,
                    React.createElement("b", null, "Error type:"),
                    " ",
                    plaidError.error_type,
                    React.createElement("br", null),
                    React.createElement("b", null, "Error code:"),
                    " ",
                    plaidError.error_code,
                    React.createElement("br", null),
                    React.createElement("b", null, "Error message:"),
                    " ",
                    plaidError.error_message,
                    React.createElement("br", null),
                    React.createElement("b", null, "Request ID:"),
                    " ",
                    plaidError.request_id,
                    React.createElement(CopyCommand_1.default, { value: plaidError.request_id, tooltipText: 'Copy request_id', _showToast: _showToast }))))),
        !!errorMessage && (React.createElement(semantic_ui_react_1.Message, { negative: true },
            React.createElement(semantic_ui_react_1.Message.Header, null, "We returned an error with this request!"),
            React.createElement(semantic_ui_react_1.Message.Content, { className: "mt-1rem" },
                React.createElement("p", null, errorMessage)))),
        viewItem === 'transactions' &&
            !!(selectedPlaidAccount === null || selectedPlaidAccount === void 0 ? void 0 : selectedPlaidAccount.import_start_date) && (React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement(semantic_ui_react_1.Message.Header, null,
                "This account has an import_start_date set to",
                ' ', selectedPlaidAccount === null || selectedPlaidAccount === void 0 ? void 0 :
                selectedPlaidAccount.import_start_date),
            React.createElement(semantic_ui_react_1.Message.Content, { className: "mt-1rem" },
                React.createElement("p", null, "No transactions before this date will be imported unless user updates this setting in the Accounts page.")))),
        viewItem === 'user-details' && (React.createElement("div", null,
            React.createElement(UserInfo_1.default, { user: user, accounts: accounts, _showToast: _showToast }))),
        viewItem === 'errors' && errorLogs ? (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
            React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" }, errorLogs.error_message ? (React.createElement("p", null, errorLogs.error_message)) : errorLogs.length == 0 ? (React.createElement("p", null, "No error logs found for this user.")) : (React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true, selectable: true },
                React.createElement(semantic_ui_react_1.Table.Header, null,
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" }, "Date"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Action"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Institution"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" }, "Event"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" }, "Error"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" }, "Error Message"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
                React.createElement(semantic_ui_react_1.Table.Body, null, plaidLogs === null || plaidLogs === void 0 ? void 0 : plaidLogs.map(sessionGroup => {
                    return sessionGroup.map((error, index) => {
                        return (React.createElement(semantic_ui_react_1.Table.Row, { key: `all-error-row-${error.id}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" }, toReadableDateSpan(error.created_at)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, error.action),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                error.institution_name,
                                " (",
                                error.institution_external_id,
                                ")"),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, error.event),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, [error.error_type, error.error_code].join(' - ')),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, error.error_message),
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align" }, error.request_id && (React.createElement(CopyCommand_1.default, { value: error.request_id, tooltipText: 'Copy request_id', _showToast: _showToast }))),
                            index == 0 && (React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap center-align", rowSpan: sessionGroup.length }, error.link_session_id && (React.createElement(CopyCommand_1.default, { value: error.link_session_id, tooltipText: 'Copy link_session_id', _showToast: _showToast }))))));
                    });
                }))))))) : (React.createElement(React.Fragment, null)),
        viewItem === 'connections' && connections ? (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
            React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" },
                React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true, selectable: true },
                    React.createElement(semantic_ui_react_1.Table.Header, null,
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Account ID"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Institution Name"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Statuses"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
                    React.createElement(semantic_ui_react_1.Table.Body, null, connections === null || connections === void 0 ? void 0 : connections.map((connection, index) => {
                        return (React.createElement(semantic_ui_react_1.Table.Row, { key: `all-account-row-${index}`, positive: (plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.access_token) == connection.access_token },
                            React.createElement(semantic_ui_react_1.Table.Cell, null, connection.account_id),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                connection.institution_name,
                                " (",
                                connection.institution_external_id,
                                ")"),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                connection.num_active > 0 ? (React.createElement("span", { className: "color--green" },
                                    "Active (",
                                    connection.num_active,
                                    ")")) : (React.createElement(React.Fragment, null)),
                                ' ',
                                connection.num_relink > 0 ? (React.createElement("span", { className: "color--red" },
                                    "Relink (",
                                    connection.num_relink,
                                    ")")) : (React.createElement(React.Fragment, null)),
                                ' ',
                                connection.num_inactive > 0 ? (React.createElement("span", { className: "color--grey" },
                                    "Inactive (",
                                    connection.num_inactive,
                                    ")")) : (React.createElement(React.Fragment, null)),
                                ' ',
                                connection.num_revoked > 0 ? (React.createElement("span", { className: "color--grey" },
                                    "Revoked (",
                                    connection.num_revoked,
                                    ")")) : (React.createElement(React.Fragment, null))),
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align no-wrap" },
                                React.createElement(CopyCommand_1.default, { style: {
                                        position: 'relative',
                                        top: '3px',
                                        right: '5px',
                                    }, value: connection.access_token, tooltipText: 'Copy access token', _showToast: _showToast }),
                                React.createElement(semantic_ui_react_1.Button, { size: "mini", labelPosition: "right", isLoading: isLoading, disabled: isLoading, icon: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        setInput(connection.access_token);
                                        setInputType('auto-detect');
                                        yield getData({
                                            value: connection.access_token,
                                        });
                                    }) },
                                    "View Accounts (",
                                    connection.num_total,
                                    ") ",
                                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" })))));
                    })))))) : (React.createElement(React.Fragment, null)),
        viewItem === 'accounts' && ((_d = plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.accounts) === null || _d === void 0 ? void 0 : _d.length) > 0 ? (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
            React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" },
                React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true, selectable: true },
                    React.createElement(semantic_ui_react_1.Table.Header, null,
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" }, "ID"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Name"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Mask"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Type"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Status"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Balance"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Balance last updated"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap", style: { width: '180px' } },
                                "Last import",
                                React.createElement(semantic_ui_react_1.Popup, { position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "info circle" }) }, "Timestamp of the last time Lunch Money imported new data from Plaid for this connection.")),
                            ' ',
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { style: { width: '180px' } },
                                "Plaid's last successful fetch",
                                React.createElement(semantic_ui_react_1.Popup, { position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "info circle" }) }, "Timestamp of the last time Plaid successfully connected for new transaction updates, regardless of whether any new data was available in the update.")),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { style: { width: '180px' } },
                                "Lunch Money's last successful fetch",
                                React.createElement(semantic_ui_react_1.Popup, { position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "info circle" }) }, "Timestamp of the last successful check from Lunch Money for updated data or timestamps from Plaid.")),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
                    React.createElement(semantic_ui_react_1.Table.Body, null, (_e = plaidAccounts === null || plaidAccounts === void 0 ? void 0 : plaidAccounts.accounts) === null || _e === void 0 ? void 0 : _e.map((account, index) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
                        return (React.createElement(semantic_ui_react_1.Table.Row, { key: `account-row-${((_a = account.plaid) === null || _a === void 0 ? void 0 : _a.account_id) ||
                                ''}-${((_b = account.lunch_money) === null || _b === void 0 ? void 0 : _b.external_id) || ''}`, positive: ((_c = account.lunch_money) === null || _c === void 0 ? void 0 : _c.id) ==
                                parseInt(selectedPlaidAccount === null || selectedPlaidAccount === void 0 ? void 0 : selectedPlaidAccount.id) },
                            React.createElement(semantic_ui_react_1.Table.Cell, null, account.lunch_money ? (account.lunch_money.id) : (React.createElement("span", { className: "color--red" }, "Missing"))),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, showDifference((_d = account.plaid) === null || _d === void 0 ? void 0 : _d.name, (_e = account.lunch_money) === null || _e === void 0 ? void 0 : _e.name)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, showDifference((_f = account.plaid) === null || _f === void 0 ? void 0 : _f.mask, (_g = account.lunch_money) === null || _g === void 0 ? void 0 : _g.mask)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, showDifference((_h = account.plaid) === null || _h === void 0 ? void 0 : _h.type, (_j = account.lunch_money) === null || _j === void 0 ? void 0 : _j.type)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                React.createElement("span", { className: ((_k = account.lunch_money) === null || _k === void 0 ? void 0 : _k.status) == 'active'
                                        ? 'color--green'
                                        : ((_l = account.lunch_money) === null || _l === void 0 ? void 0 : _l.status) == 'inactive'
                                            ? 'color--grey'
                                            : ((_m = account.lunch_money) === null || _m === void 0 ? void 0 : _m.status) == 'relink'
                                                ? 'color--red'
                                                : 'color--grey' }, (_o = account.lunch_money) === null || _o === void 0 ? void 0 : _o.status)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, showDifference((0, format_1.toPrice)(((_p = account.plaid) === null || _p === void 0 ? void 0 : _p.type) === 'credit'
                                ? (_q = account.plaid) === null || _q === void 0 ? void 0 : _q.balances.current
                                : ((_r = account.plaid) === null || _r === void 0 ? void 0 : _r.balances.current) ||
                                    ((_s = account.plaid) === null || _s === void 0 ? void 0 : _s.balances.available), (_t = account.plaid) === null || _t === void 0 ? void 0 : _t.balances.iso_currency_code.toLowerCase()), (0, format_1.toPrice)((_u = account.lunch_money) === null || _u === void 0 ? void 0 : _u.balance, (_v = account.lunch_money) === null || _v === void 0 ? void 0 : _v.currency))),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, toReadableDateSpan((_w = account.lunch_money) === null || _w === void 0 ? void 0 : _w.balance_last_update)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                ((_x = account.lunch_money) === null || _x === void 0 ? void 0 : _x.type) == 'investment' &&
                                    (((_y = account.lunch_money) === null || _y === void 0 ? void 0 : _y.available_products) || '').indexOf('investments') > -1 ? (React.createElement("b", null, "Available, not enabled")) : ((_z = account.lunch_money) === null || _z === void 0 ? void 0 : _z.type) == 'investment' &&
                                    ((_1 = (((_0 = account.lunch_money) === null || _0 === void 0 ? void 0 : _0.available_products) || '')) === null || _1 === void 0 ? void 0 : _1.indexOf('investments')) == -1 &&
                                    ((_3 = (((_2 = account.lunch_money) === null || _2 === void 0 ? void 0 : _2.billed_products) || '')) === null || _3 === void 0 ? void 0 : _3.indexOf('investments')) == -1 ? (React.createElement("b", null, "Not supported")) : (toReadableDateSpan((_4 = account.lunch_money) === null || _4 === void 0 ? void 0 : _4.last_import)),
                                ((_5 = account.lunch_money) === null || _5 === void 0 ? void 0 : _5.skip_transactions) && (React.createElement("b", { className: "display--block" }, "Disabled by user"))),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, toReadableDateSpan((_6 = account.lunch_money) === null || _6 === void 0 ? void 0 : _6.plaid_last_successful_update)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, toReadableDateSpan((_7 = account.lunch_money) === null || _7 === void 0 ? void 0 : _7.last_fetch)),
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement(CopyCommand_1.default, { value: ((_8 = account.plaid) === null || _8 === void 0 ? void 0 : _8.account_id) ||
                                        ((_9 = account.lunch_money) === null || _9 === void 0 ? void 0 : _9.external_id), tooltipText: 'Copy account_id', _showToast: _showToast }),
                                React.createElement(semantic_ui_react_1.Button, { size: "mini", disabled: isLoading || !((_10 = account.lunch_money) === null || _10 === void 0 ? void 0 : _10.id), labelPosition: "right", isLoading: isLoading, icon: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        var _11, _12;
                                        setSelectedPlaidAccount(account.lunch_money);
                                        setInput((_11 = account.lunch_money) === null || _11 === void 0 ? void 0 : _11.id);
                                        setInputType('plaid_account_id');
                                        yield getData({
                                            value: (_12 = account.lunch_money) === null || _12 === void 0 ? void 0 : _12.id,
                                            type: 'plaid_account_id',
                                        });
                                    }) },
                                    "View Transactions ",
                                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" })))));
                    })))))) : (React.createElement(React.Fragment, null)),
        viewItem === 'transactions' && (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
            React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" }, (transactions === null || transactions === void 0 ? void 0 : transactions.length) == 0 ? (React.createElement("p", null,
                "No transactions found for this time period (",
                startDate,
                " -",
                ' ',
                endDate,
                ") for this account (", selectedPlaidAccount === null || selectedPlaidAccount === void 0 ? void 0 :
                selectedPlaidAccount.id,
                ").")) : (React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true, selectable: true },
                React.createElement(semantic_ui_react_1.Table.Header, null,
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Date"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Name"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Original Name"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Amount"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Status"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Imported on"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
                React.createElement(semantic_ui_react_1.Table.Body, null, transactions === null || transactions === void 0 ? void 0 : transactions.map(tx => {
                    var _a, _b, _c;
                    return (React.createElement(semantic_ui_react_1.Table.Row, { key: tx.transaction_id },
                        React.createElement(semantic_ui_react_1.Table.Cell, null, ((_a = tx.lunch_money) === null || _a === void 0 ? void 0 : _a.id)
                            ? showDifference(Moment(tx.date).format('MMM D, YYYY'), Moment((_b = tx.lunch_money) === null || _b === void 0 ? void 0 : _b.date).format('MMM D, YYYY'))
                            : Moment(tx.date).format('MMM D, YYYY')),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, tx.name),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, tx.original_name),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align" },
                            React.createElement("span", { className: tx.amount < 0 ? 'color--green' : '' }, (0, format_1.toPrice)(tx.amount * -1, tx.iso_currency_code.toLowerCase()))),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, tx.pending ? 'Pending' : 'Posted'),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, toReadableDateSpan((_c = tx.lunch_money) === null || _c === void 0 ? void 0 : _c.created_at)),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align" },
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "search plus", className: "clickable mr-1rem", onClick: () => {
                                        const metadata = Object.assign({}, tx);
                                        delete metadata.lunch_money;
                                        setShowMetadata(metadata);
                                    } }) }, "View transaction metadata"),
                            React.createElement(CopyCommand_1.default, { value: tx.id || tx.investment_transaction_id, tooltipText: 'Copy transaction_id', _showToast: _showToast }))));
                }))))))),
        React.createElement(semantic_ui_react_1.Modal, { open: !!showMetadata, closeOnEscape: true, closeOnDimmerClick: true, 
            // size={'small'}
            onClose: () => {
                setShowMetadata(null);
            } },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Plaid Transaction Metadata"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(react_json_view_1.default, { theme: document.body.classList.contains('dark')
                        ? 'tomorrow'
                        : 'rjv-default', enableClipboard: true, displayObjectSize: false, name: 'transaction', src: showMetadata, displayDataTypes: false })),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setShowMetadata(null);
                    }), content: "Close" })))));
};
exports.default = Admin;
