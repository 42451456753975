"use strict";
/**
 *  AddModal.tsx
 *  Rules add modal
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@helpers/format");
const rules_1 = require("@actions/rules");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const EditableTags_1 = require("@components/elements/EditableTags");
const RuleActionOptions_1 = require("@components/Rules/Modal/RuleActionOptions");
const RuleConditionOptions_1 = require("@components/Rules/Modal/RuleConditionOptions");
const ModalProvider_1 = require("@providers/ModalProvider");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const UserProvider_1 = require("@providers/UserProvider");
const rules_2 = require("@helpers/rules");
const options_1 = require("@helpers/options");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const TagsProvider_1 = require("@/providers/TagsProvider");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const AddModal = ({ useModal, utils, data, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a, _b, _c, _d, _e, _f;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const [buttonIsLoading, setButtonIsLoading] = (0, react_1.useState)(false);
    // New
    const [conditions, setConditions] = (0, react_1.useState)({
        on_plaid: true,
        on_api: true,
        on_csv: true,
    });
    const [conditionsForm, setConditionsForm] = (0, react_1.useState)(null);
    const [actions, setActions] = (0, react_1.useState)(((_c = (_b = (_a = _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.actions) || {});
    const [actionsForm, setActionsForm] = (0, react_1.useState)(null);
    const [triggersForm, setTriggersForm] = (0, react_1.useState)(null);
    // Rules
    const [rule, setRule] = (0, react_1.useState)(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = (0, react_1.useState)(false);
    // Only used by Recurring Expenses > Edit/Add Rule
    const [showApplyRules, setShowApplyRules] = (0, react_1.useState)(null);
    const populateRule = () => __awaiter(void 0, void 0, void 0, function* () {
        const _rule = yield (0, rules_1.getRule)(_modal.data.current.rule_id);
        if (_rule) {
            setRule(_rule);
        }
        else {
            utils._showToast({
                message: 'This rule no longer exists.',
                type: 'info',
            });
            setVisibility(false);
        }
    });
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d, _e, _f;
        setConditions(Object.assign(Object.assign({}, conditions), (_c = (_b = (_a = _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.state) === null || _c === void 0 ? void 0 : _c.conditions));
        setActions(Object.assign(Object.assign({}, actions), (_f = (_e = (_d = _modal.data) === null || _d === void 0 ? void 0 : _d.current) === null || _e === void 0 ? void 0 : _e.state) === null || _f === void 0 ? void 0 : _f.actions));
    }, []);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d, _e, _f;
        if ((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.rule_id) {
            populateRule();
            return;
        }
        if ((_d = (_c = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.rule) {
            data.rule = _modal.data.current.rule;
        }
        setRule(((_f = (_e = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _e === void 0 ? void 0 : _e.current) === null || _f === void 0 ? void 0 : _f.rule) || data.rule);
    }, [data]);
    (0, react_1.useEffect)(() => {
        if (!!rule) {
            const _conditions = {};
            const _actions = {};
            // Set everything
            if (rule === null || rule === void 0 ? void 0 : rule.criteria_asset_id) {
                _conditions.account = { asset_id: rule.criteria_asset_id };
            }
            if (rule === null || rule === void 0 ? void 0 : rule.criteria_plaid_account_id) {
                _conditions.account = {
                    plaid_account_id: rule.criteria_plaid_account_id,
                };
            }
            if (rule === null || rule === void 0 ? void 0 : rule.criteria_category_id) {
                _conditions.category_id = rule.criteria_category_id;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.criteria_payee_name) {
                _conditions.payee = {
                    name: rule.criteria_payee_name,
                    match: rule.criteria_payee_name_match,
                };
            }
            if (rule === null || rule === void 0 ? void 0 : rule.criteria_notes) {
                _conditions.notes = {
                    name: rule.criteria_notes,
                    match: rule.criteria_notes_match,
                };
            }
            if (rule === null || rule === void 0 ? void 0 : rule.criteria_day) {
                _conditions.day = {
                    value_1: parseInt(rule.criteria_day),
                    value_2: parseInt(rule === null || rule === void 0 ? void 0 : rule.criteria_day_2) || null,
                    match: rule.criteria_day_match,
                };
            }
            if (rule === null || rule === void 0 ? void 0 : rule.criteria_amount) {
                if ((rule === null || rule === void 0 ? void 0 : rule.criteria_amount_match) == 'between') {
                    _conditions.amount = {
                        value_1: Math.abs((rule === null || rule === void 0 ? void 0 : rule.criteria_amount) || null) >
                            Math.abs((rule === null || rule === void 0 ? void 0 : rule.criteria_amount_2) || null)
                            ? Math.abs((rule === null || rule === void 0 ? void 0 : rule.criteria_amount_2) || null)
                            : Math.abs((rule === null || rule === void 0 ? void 0 : rule.criteria_amount) || null),
                        value_2: Math.abs((rule === null || rule === void 0 ? void 0 : rule.criteria_amount) || null) >
                            Math.abs((rule === null || rule === void 0 ? void 0 : rule.criteria_amount_2) || null)
                            ? Math.abs((rule === null || rule === void 0 ? void 0 : rule.criteria_amount) || null)
                            : Math.abs((rule === null || rule === void 0 ? void 0 : rule.criteria_amount_2) || null),
                    };
                }
                else {
                    _conditions.amount = {
                        value_1: Math.abs(rule === null || rule === void 0 ? void 0 : rule.criteria_amount) || null,
                    };
                }
                _conditions.amount.currency = rule === null || rule === void 0 ? void 0 : rule.criteria_amount_currency;
                _conditions.amount.match = rule === null || rule === void 0 ? void 0 : rule.criteria_amount_match;
                _conditions.amount.type =
                    (rule === null || rule === void 0 ? void 0 : rule.criteria_amount) < 0 ? 'credits' : 'expenses';
            }
            _conditions.on_plaid = !!(rule === null || rule === void 0 ? void 0 : rule.criteria_on_plaid);
            _conditions.on_manual = !!(rule === null || rule === void 0 ? void 0 : rule.criteria_on_manual);
            _conditions.on_csv = !!(rule === null || rule === void 0 ? void 0 : rule.criteria_on_csv);
            _conditions.on_api = !!(rule === null || rule === void 0 ? void 0 : rule.criteria_on_api);
            if (rule === null || rule === void 0 ? void 0 : rule.criteria_priority) {
                _conditions.priority = rule === null || rule === void 0 ? void 0 : rule.criteria_priority;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.payee_name) {
                _actions.payee_name = rule === null || rule === void 0 ? void 0 : rule.payee_name;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.notes) {
                _actions.notes = rule === null || rule === void 0 ? void 0 : rule.notes;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.category_id) {
                _actions.category_id = rule === null || rule === void 0 ? void 0 : rule.category_id;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.tags) {
                _actions.tags = rule === null || rule === void 0 ? void 0 : rule.tags.map(o => o.id);
            }
            if (rule === null || rule === void 0 ? void 0 : rule.recurring_id) {
                _actions.recurring_id = rule === null || rule === void 0 ? void 0 : rule.recurring_id;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.mark_as_reviewed) {
                _actions.mark_as_reviewed = rule === null || rule === void 0 ? void 0 : rule.mark_as_reviewed;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.mark_as_unreviewed) {
                _actions.mark_as_unreviewed = rule === null || rule === void 0 ? void 0 : rule.mark_as_unreviewed;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.set_uncategorized) {
                _actions.set_uncategorized = rule === null || rule === void 0 ? void 0 : rule.set_uncategorized;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.should_send_email) {
                _actions.should_send_email = rule === null || rule === void 0 ? void 0 : rule.should_send_email;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.stop_processing_others) {
                _actions.stop_processing_others = rule === null || rule === void 0 ? void 0 : rule.stop_processing_others;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.one_time_rule) {
                _actions.one_time_rule = rule === null || rule === void 0 ? void 0 : rule.one_time_rule;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.run_on_update) {
                _conditions.run_on_update = rule === null || rule === void 0 ? void 0 : rule.run_on_update;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.delete_transaction_id) {
                _actions.delete_transaction_id = rule === null || rule === void 0 ? void 0 : rule.delete_transaction_id;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.skip_recurring) {
                _actions.skip_recurring = rule === null || rule === void 0 ? void 0 : rule.skip_recurring;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.skip_rule) {
                _actions.skip_rule = rule === null || rule === void 0 ? void 0 : rule.skip_rule;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.split) {
                _actions.split = rule === null || rule === void 0 ? void 0 : rule.split;
            }
            if (rule === null || rule === void 0 ? void 0 : rule.description) {
                _actions.description = rule === null || rule === void 0 ? void 0 : rule.description;
            }
            // Override with any updates
            setConditions(Object.assign(Object.assign({}, _conditions), _modal.data.current.state.conditions));
            setActions(Object.assign(Object.assign({}, _actions), _modal.data.current.state.actions));
        }
    }, [rule]);
    // Setup
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d;
        useModal({
            header: (React.createElement("div", null,
                ((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.previous) && (React.createElement("span", { className: "header-text-small clickable link", onClick: () => {
                        var _a, _b;
                        // Reset
                        _modal.data.current = Object.assign(Object.assign({}, _modal.data.current), { state: Object.assign(Object.assign({}, _modal.data.current.state), { conditions: {}, actions: {} }) });
                        switchView((_b = (_a = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.previous);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "angle left" }),
                    " ", (_d = (_c = _modal === null || _modal === void 0 ? void 0 : _modal.data) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 :
                    _d.previous_text)),
                React.createElement("span", { className: "header-text-large" }, (rule === null || rule === void 0 ? void 0 : rule.rule_id)
                    ? 'Edit transaction rule'
                    : 'Create transaction rule'))),
            middleButton: React.createElement(React.Fragment, null),
            leftButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, className: "no-border", onClick: () => {
                    setVisibility(false);
                } }, "Cancel")),
        });
        return () => {
            useModal({
                header: null,
                middleButton: null,
                leftButton: null,
            });
        };
    }, [rule]);
    const submit = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (_applyRules = false) {
        if (conditions.amount) {
            const _value_1 = conditions.amount.value_1;
            const _value_2 = conditions.amount.value_2;
            conditions.amount.value_1 = _user.settings['show_debits_as_negative']
                ? conditions.amount.type == 'credits'
                    ? _value_1 * -1
                    : _value_1
                : conditions.amount.type == 'credits'
                    ? _value_1 * -1
                    : _value_1;
            conditions.amount.value_2 = _user.settings['show_debits_as_negative']
                ? conditions.amount.type == 'credits'
                    ? _value_2 * -1
                    : _value_2
                : conditions.amount.type == 'credits'
                    ? _value_2 * -1
                    : _value_2;
        }
        if (actions.hasOwnProperty('mark_as_reviewed')) {
            actions['mark_as_reviewed'] = true;
        }
        if (actions.hasOwnProperty('mark_as_unreviewed')) {
            actions['mark_as_unreviewed'] = true;
        }
        if (actions.hasOwnProperty('set_uncategorized')) {
            actions['set_uncategorized'] = true;
        }
        if (actions.hasOwnProperty('should_send_email')) {
            actions['should_send_email'] = true;
        }
        if (!(rule === null || rule === void 0 ? void 0 : rule.rule_id)) {
            const results = yield utils._process(rules_1.createRule)({
                conditions,
                actions,
            });
            if (!results.error) {
                // Add results.data to rules array
                utils._showToast({
                    message: 'Successfully created rule',
                    type: 'success',
                });
                if (data.submitCallback) {
                    data.submitCallback(results, _applyRules);
                }
                if (_modal.data.current.previous == 'EDIT_RECURRING') {
                    if (_applyRules) {
                        setShowApplyRules(results.data.rule_criteria_id);
                    }
                    else {
                        switchView('EDIT_RECURRING');
                    }
                }
                else if (_modal.data.current.previous == 'DETAILS_VIEW') {
                    if (_applyRules) {
                        data.setShowDryRunRules &&
                            data.setShowDryRunRules(results.data.rule_criteria_id);
                    }
                    setVisibility(false);
                }
                else {
                    setVisibility(false);
                }
            }
        }
        else {
            const results = yield utils._process(rules_1.updateRule)(rule.rule_criteria_id, {
                conditions,
                actions,
            });
            if (!results.error) {
                utils._showToast({
                    message: 'Successfully updated rule',
                    type: 'success',
                });
                if (data.submitCallback) {
                    data.submitCallback(results, _applyRules, rule.rule_criteria_id);
                }
                if (_modal.data.current.previous == 'EDIT_RECURRING') {
                    if (_applyRules) {
                        setShowApplyRules(rule.rule_criteria_id);
                    }
                    else {
                        switchView('EDIT_RECURRING');
                    }
                }
                else {
                    setVisibility(false);
                }
            }
        }
    });
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(React.Fragment, null,
                React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: false, className: "no-border", loading: buttonIsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setButtonIsLoading(true);
                        yield submit();
                        setButtonIsLoading(false);
                    }) }, rule ? 'Save' : 'Create'),
                !(rule === null || rule === void 0 ? void 0 : rule.criteria_suggested) && (React.createElement(semantic_ui_react_1.Button, { color: "orange", disabled: !actions.payee_name &&
                        !actions.notes &&
                        !actions.category_id &&
                        !actions.tags &&
                        !actions.recurring_id &&
                        !actions.mark_as_reviewed &&
                        !actions.mark_as_unreviewed &&
                        !actions.set_uncategorized &&
                        !actions.split, className: "no-border", loading: buttonIsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setButtonIsLoading(true);
                        yield submit(true);
                        setButtonIsLoading(false);
                    }) }, rule ? 'Save & Apply' : 'Create & Apply')))),
        });
        return () => {
            useModal({
                rightButton: null,
            });
        };
    }, [buttonIsLoading, rule, conditions, actions]);
    const TriggerElement = (label, conditionKey) => {
        return (React.createElement(React.Fragment, { key: `trigger-${conditionKey}` },
            React.createElement("div", { className: "transaction-details color-border-0 padded mt-0 mb-0" },
                React.createElement("div", { className: "flex--space-between-flex-start" },
                    React.createElement("div", null,
                        React.createElement("label", null, label)),
                    React.createElement(semantic_ui_react_1.Icon, { name: "x", className: "clickable", onClick: () => {
                            setDisableOnClickOutside(true);
                            setConditions(Object.assign(Object.assign({}, conditions), { [conditionKey]: false }));
                        } }))),
            React.createElement("p", { className: "divider-line mt-05rem mb-05rem" },
                React.createElement("span", null, "or"))));
    };
    const ConditionElement = (label, labelElement, conditionKey, formElement) => {
        return (React.createElement(React.Fragment, { key: `condition-${conditionKey}` },
            React.createElement("div", { className: "transaction-details color-border-1 padded mt-0 mb-0" },
                React.createElement("div", { className: "flex--space-between-flex-start" },
                    React.createElement("div", null,
                        React.createElement("label", null, label),
                        " ",
                        labelElement),
                    React.createElement(semantic_ui_react_1.Icon, { name: "x", className: "clickable", onClick: () => {
                            setDisableOnClickOutside(true);
                            const _conditions = Object.assign({}, conditions);
                            delete _conditions[conditionKey];
                            setConditions(_conditions);
                        } })),
                formElement),
            React.createElement("p", { className: "divider-line mt-05rem mb-05rem" },
                React.createElement("span", null, "and"))));
    };
    const ActionElement = (label, conditionKey, formElement, tooltip = null) => {
        return (React.createElement(React.Fragment, { key: `action-${conditionKey}` },
            React.createElement("div", { className: "transaction-details color-border-2 padded mt-0 mb-0" },
                React.createElement("div", { className: "flex--space-between-flex-start" },
                    React.createElement("div", null,
                        React.createElement("label", null, label),
                        ' ',
                        tooltip && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "question circle" }), inverted: true, size: "small" }, tooltip))),
                    React.createElement(semantic_ui_react_1.Icon, { name: "x", className: "clickable", onClick: () => {
                            setDisableOnClickOutside(true);
                            const _actions = Object.assign({}, actions);
                            delete _actions[conditionKey];
                            setActions(_actions);
                        } })),
                formElement),
            React.createElement("p", { className: "divider-line mt-05rem mb-05rem" },
                React.createElement("span", null, "and"))));
    };
    (0, react_1.useEffect)(() => {
        const _conditionsForm = [];
        const _triggersForm = [];
        const keys = Object.keys(conditions);
        keys.forEach(key => {
            if (key == 'payee') {
                _conditionsForm.push(ConditionElement('Payee name', React.createElement(semantic_ui_react_1.Dropdown, { className: "p-match-inline", inline: true, options: options_1.STRING_MATCH_TYPES, defaultValue: conditions['payee'].match, onChange: (e, { value }) => {
                        setConditions(Object.assign(Object.assign({}, conditions), { payee: Object.assign(Object.assign({}, conditions['payee']), { match: value }) }));
                    } }), 'payee', React.createElement(EditableString_1.default, { identifier: `filter-name`, placeholder: "Starbucks", firstValue: conditions['payee'].name, location: 'modal', state: 'Editing', autosuggest: true, shouldSaveOnChange: true, onSave: (name) => __awaiter(void 0, void 0, void 0, function* () {
                        setConditions(Object.assign(Object.assign({}, conditions), { payee: Object.assign(Object.assign({}, conditions['payee']), { name: name }) }));
                    }) })));
            }
            else if (key == 'notes') {
                _conditionsForm.push(ConditionElement('Notes', React.createElement(semantic_ui_react_1.Dropdown, { className: "p-match-inline", inline: true, options: options_1.STRING_MATCH_TYPES, defaultValue: conditions['notes'].match, onChange: (e, { value }) => {
                        setConditions(Object.assign(Object.assign({}, conditions), { notes: Object.assign(Object.assign({}, conditions['notes']), { match: value }) }));
                    } }), 'notes', React.createElement(EditableString_1.default, { identifier: `filter-name`, placeholder: "Starbucks", firstValue: conditions['notes'].name, location: 'modal', state: 'Editing', autosuggest: false, shouldSaveOnChange: true, onSave: (name) => __awaiter(void 0, void 0, void 0, function* () {
                        setConditions(Object.assign(Object.assign({}, conditions), { notes: Object.assign(Object.assign({}, conditions['notes']), { name: name }) }));
                    }) })));
            }
            else if (key == 'category_id') {
                _conditionsForm.push(ConditionElement('Category', React.createElement(React.Fragment, null), 'category_id', React.createElement(EditableCategory_1.default, { identifier: `bulk-edit-condition-category`, firstValue: conditions[key], placeholder: 'Type to search or add new', state: 'Editing', location: 'modal', onSave: (category) => __awaiter(void 0, void 0, void 0, function* () {
                        setConditions(Object.assign(Object.assign({}, conditions), { category_id: category }));
                    }) })));
            }
            else if (key == 'amount') {
                _conditionsForm.push(ConditionElement('Amount Matches', React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Dropdown, { className: "ml-05rem mr-05rem p-match-inline", inline: true, options: [
                            {
                                key: 'credits',
                                value: 'credits',
                                text: 'income/credits',
                            },
                            {
                                key: 'expenses',
                                value: 'expenses',
                                text: 'expenses/debits',
                            },
                        ], defaultValue: conditions['amount'].type || 'expenses', onChange: (e, { value }) => {
                            setConditions(Object.assign(Object.assign({}, conditions), { amount: Object.assign(Object.assign({}, conditions['amount']), { type: value }) }));
                        } }),
                    React.createElement(semantic_ui_react_1.Dropdown, { className: "p-match-inline", inline: true, options: options_1.AMOUNT_MATCH_TYPES, defaultValue: conditions['amount'].match || 'exactly', onChange: (e, { value }) => {
                            setConditions(Object.assign(Object.assign({}, conditions), { amount: Object.assign(Object.assign({}, conditions['amount']), { match: value }) }));
                        } })), 'amount', conditions['amount'].match == 'between' ? (React.createElement("div", { className: "flex--align-center" },
                    React.createElement(EditablePrice_1.default, { identifier: "criteria-amount", amount: conditions['amount'].value_1 || undefined, currency: conditions['amount'].currency, location: 'modal', autoFocus: false, state: 'Editing', saveOnChange: true, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                            setConditions(Object.assign(Object.assign({}, conditions), { amount: Object.assign(Object.assign({}, conditions['amount']), { value_1: amount, currency: currency }) }));
                        }) }),
                    React.createElement("span", { className: "mr-05rem ml-05rem" }, "&"),
                    React.createElement(EditablePrice_1.default, { identifier: `criteria-amount-2`, amount: conditions['amount'].value_2 || undefined, currency: conditions['amount'].currency, location: 'modal', state: 'Editing', saveOnChange: true, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                            setConditions(Object.assign(Object.assign({}, conditions), { amount: Object.assign(Object.assign({}, conditions['amount']), { value_2: amount, currency: currency }) }));
                        }) }))) : (React.createElement(EditablePrice_1.default, { identifier: "criteria-amount", amount: conditions['amount'].value_1 || undefined, currency: conditions['amount'].currency, location: 'modal', autoFocus: false, state: 'Editing', saveOnChange: true, onSave: (amount, currency) => __awaiter(void 0, void 0, void 0, function* () {
                        setConditions(Object.assign(Object.assign({}, conditions), { amount: Object.assign(Object.assign({}, conditions['amount']), { value_1: amount, currency: currency }) }));
                    }) }))));
            }
            else if (key == 'day') {
                _conditionsForm.push(ConditionElement('Day is', React.createElement(semantic_ui_react_1.Dropdown, { inline: true, className: "p-match-inline", options: options_1.DAY_MATCH_TYPES, defaultValue: conditions['day'].match || 'before', onChange: (e, { value }) => {
                        setConditions(Object.assign(Object.assign({}, conditions), { day: Object.assign(Object.assign({}, conditions['day']), { match: value }) }));
                    } }), 'day', conditions['day'].match == 'between' ? (React.createElement("div", { className: "flex--align-center" },
                    React.createElement(semantic_ui_react_1.Dropdown, { className: "criteria-day category-dropdown p-category-modal", fluid: true, search: true, selection: true, placeholder: "Day of month", value: conditions['day'].value_1 || undefined, options: getDayOptions(), onChange: (e, { value }) => {
                            setConditions(Object.assign(Object.assign({}, conditions), { day: Object.assign(Object.assign({}, conditions['day']), { value_1: value }) }));
                        } }),
                    React.createElement("span", { className: "mr-05rem ml-05rem" }, "&"),
                    React.createElement(semantic_ui_react_1.Dropdown, { className: "criteria-day category-dropdown p-category-modal", fluid: true, selection: true, placeholder: "Day of month", search: true, value: conditions['day'].value_2 || undefined, options: getDayOptions(), onChange: (e, { value }) => {
                            setConditions(Object.assign(Object.assign({}, conditions), { day: Object.assign(Object.assign({}, conditions['day']), { value_2: value }) }));
                        } }))) : (React.createElement(semantic_ui_react_1.Dropdown, { className: "criteria-day category-dropdown p-category-modal", fluid: true, selection: true, placeholder: "Select day of month", search: true, value: conditions['day'].value_1 || undefined, options: getDayOptions(), onChange: (e, { value }) => {
                        setConditions(Object.assign(Object.assign({}, conditions), { day: Object.assign(Object.assign({}, conditions['day']), { value_1: value }) }));
                    } }))));
            }
            else if (key == 'account') {
                _conditionsForm.push(ConditionElement('Account', React.createElement(React.Fragment, null), 'account', React.createElement(EditableAsset_1.default, { assignableOnly: false, defaultSelection: Object.assign({}, (conditions['account'].asset_id
                        ? { id: conditions['account'].asset_id, source: 'manual' }
                        : conditions['account'].plaid_account_id
                            ? {
                                id: conditions['account'].plaid_account_id,
                                source: 'plaid',
                            }
                            : null)), onSave: obj => {
                        setConditions(Object.assign(Object.assign({}, conditions), { 
                            // Reset both
                            account: Object.assign({ asset_id: null, plaid_account_id: null }, {
                                [obj.source == 'plaid' ? 'plaid_account_id' : 'asset_id']: obj.id > 0 ? obj.id : null,
                            }) }));
                    } })));
            }
            else if (key == 'on_plaid' &&
                conditions.on_plaid &&
                _assets.plaidAccounts.length > 0) {
                _triggersForm.push(TriggerElement('transaction added via bank sync', 'on_plaid'));
            }
            else if (key == 'on_csv' &&
                conditions.on_csv &&
                _assets.assets.length > 0) {
                _triggersForm.push(TriggerElement('transaction added via CSV upload', 'on_csv'));
            }
            else if (key == 'on_manual' && conditions.on_manual) {
                _triggersForm.push(TriggerElement('transaction added via manual input', 'on_manual'));
            }
            else if (key == 'on_api' && conditions.on_api && _assets.hasAPIKey) {
                _triggersForm.push(TriggerElement('transaction added via developer API', 'on_api'));
            }
        });
        if (_conditionsForm.length > 0) {
            setConditionsForm(_conditionsForm);
        }
        else {
            setConditionsForm(null);
        }
        setTriggersForm(_triggersForm);
        _modal.data.current = Object.assign(Object.assign({}, _modal.data.current), { state: Object.assign(Object.assign({}, _modal.data.current.state), { conditions }) });
    }, [conditions]);
    (0, react_1.useEffect)(() => {
        const forms = [];
        const keys = Object.keys(actions);
        if (keys.length == 0) {
            setActionsForm(null);
            _modal.data.current = Object.assign(Object.assign({}, _modal.data.current), { state: Object.assign(Object.assign({}, _modal.data.current.state), { actions: {} }) });
            return;
        }
        keys.forEach(key => {
            var _a, _b, _c, _d;
            if (['payee_name', 'notes'].indexOf(key) > -1) {
                forms.push(ActionElement(`set ${key.replace('_', ' ')}`, key, React.createElement(EditableString_1.default, { identifier: `filter-name-${key}`, placeholder: key == 'notes' ? '' : 'Starbucks', firstValue: actions[key] || undefined, location: 'modal', state: 'Editing', autosuggest: true, shouldSaveOnChange: true, onSave: (value) => __awaiter(void 0, void 0, void 0, function* () {
                        setActions(Object.assign(Object.assign({}, actions), { [key]: value }));
                    }) })));
            }
            else if (['category_id'].indexOf(key) > -1) {
                forms.push(ActionElement('set Category', key, React.createElement(EditableCategory_1.default, { identifier: `bulk-edit-category`, firstValue: actions[key], placeholder: 'Type to search or add new', state: 'Editing', location: 'modal', onSave: (category) => __awaiter(void 0, void 0, void 0, function* () {
                        setActions(Object.assign(Object.assign({}, actions), { [key]: category }));
                    }) })));
            }
            else if (key == 'tags') {
                forms.push(ActionElement('set Tags', key, React.createElement(EditableTags_1.default, { placeholder: 'Type to search or add new', allowAdd: true, initialValue: actions[key] || undefined, onChange: value => {
                        setActions(Object.assign(Object.assign({}, actions), { [key]: value }));
                    } })));
            }
            else if (key == 'recurring_id') {
                forms.push(ActionElement(`Link to Recurring`, key, React.createElement(semantic_ui_react_1.Dropdown, { className: `category-dropdown p-category-modal`, fluid: true, search: true, selection: true, value: actions.recurring_id, placeholder: 'Select recurring item', options: _recurring.dropdownOptions, onChange: (e, o) => {
                        setActions(Object.assign(Object.assign({}, actions), { [key]: o.value }));
                    } })));
            }
            else if (['delete_transaction_id'].indexOf(key) > -1) {
                forms.push(ActionElement('Delete transaction', key, React.createElement("span", { className: "monospace" },
                    ((_a = _modal.data.current.transaction) === null || _a === void 0 ? void 0 : _a.formatted_date) ||
                        rule.delete_transaction_date,
                    ",",
                    ' ',
                    ((_b = _modal.data.current.transaction) === null || _b === void 0 ? void 0 : _b.display_name) ||
                        rule.delete_transaction_payee,
                    ",",
                    ' ',
                    (0, format_1.toPrice)(((_c = _modal.data.current.transaction) === null || _c === void 0 ? void 0 : _c.amount) ||
                        rule.delete_transaction_amount, ((_d = _modal.data.current.transaction) === null || _d === void 0 ? void 0 : _d.currency) ||
                        rule.delete_transaction_currency))));
            }
            else if (['category_id'].indexOf(key) > -1) {
                forms.push(ActionElement('set Category', key, React.createElement(EditableCategory_1.default, { identifier: `bulk-edit-category`, firstValue: actions[key], placeholder: 'Type to search or add new', state: 'Editing', location: 'modal', onSave: (category) => __awaiter(void 0, void 0, void 0, function* () {
                        setActions(Object.assign(Object.assign({}, actions), { [key]: category }));
                    }) })));
            }
            else if (['set_uncategorized'].indexOf(key) > -1) {
                forms.push(ActionElement('set uncategorized', key, null, 'A transaction matching these criteria will not be assigned a category.'));
            }
            else if (['skip_recurring'].indexOf(key) > -1) {
                forms.push(ActionElement("Don't link to recurring", key, null, 'A transaction matching these criteria will not create a new suggested recurring item.'));
            }
            else if (['skip_rule'].indexOf(key) > -1) {
                forms.push(ActionElement("Don't create a rule", key, null, 'A transaction matching these criteria will not trigger the creation of a new rule.'));
            }
            else if (['mark_as_reviewed'].indexOf(key) > -1) {
                forms.push(ActionElement('Mark as reviewed', key, null));
            }
            else if (['mark_as_unreviewed'].indexOf(key) > -1) {
                forms.push(ActionElement('Mark as unreviewed', key, null));
            }
            else if (['should_send_email'].indexOf(key) > -1) {
                forms.push(ActionElement('Send me an email', key, null));
            }
        });
        if (forms.length > 0) {
            setActionsForm(forms);
        }
        else {
            _modal.data.current = Object.assign(Object.assign({}, _modal.data.current), { state: Object.assign(Object.assign({}, _modal.data.current.state), { actions: {} }) });
            setActionsForm(null);
        }
        _modal.data.current = Object.assign(Object.assign({}, _modal.data.current), { state: Object.assign(Object.assign({}, _modal.data.current.state), { actions }) });
    }, [actions]);
    const getDayOptions = () => {
        const options = [];
        for (let i = 1; i <= 31; i++) {
            options.push({
                key: i,
                value: i,
                text: i,
            });
        }
        return options;
    };
    return (React.createElement("div", { id: "transactions-modal-detail-view" },
        React.createElement("h2", null, "If.."),
        conditionsForm,
        React.createElement(RuleConditionOptions_1.default, { conditions: conditions, setConditions: setConditions, setDisableOnClickOutside: setDisableOnClickOutside }),
        React.createElement("h2", { className: "mt-2rem" }, "Then.."),
        actions.hasOwnProperty('split') &&
            Object.keys(actions).filter(o => o != 'should_send_email' &&
                o != 'run_on_update' &&
                o != 'stop_processing_others' &&
                o != 'one_time_rule' &&
                o != 'description').length > 1 && (React.createElement(semantic_ui_react_1.Message, { error: true }, "You can't have both a split and property updates!")),
        actionsForm,
        actions.hasOwnProperty('split') && !!((_d = actions.split) === null || _d === void 0 ? void 0 : _d.length) && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "display--flex" },
                React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", icon: true, labelPosition: "left", 
                    // basic
                    // color="orange"
                    className: "mb-05rem", onClick: () => {
                        // If we go here, we have to save the current changes
                        switchView('SPLIT_RULE');
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" }),
                    "Split into ",
                    actions.split.length),
                React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "mini", basic: true, color: "orange", className: "mb-05rem", onClick: () => {
                        const _actions = Object.assign({}, actions);
                        delete _actions.split;
                        setActions(_actions);
                    } }, "Remove split")),
            React.createElement("p", { className: `divider-line mb-05rem` },
                React.createElement("span", null, "and")))),
        React.createElement(RuleActionOptions_1.default, { actions: actions, setActions: setActions, switchView: switchView, setDisableOnClickOutside: setDisableOnClickOutside, transactionId: (_f = (_e = _modal.data.current) === null || _e === void 0 ? void 0 : _e.transaction) === null || _f === void 0 ? void 0 : _f.id }),
        React.createElement("h2", { className: "mt-2rem" }, "Finally.."),
        React.createElement("div", { className: "transaction-details" },
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, label: "Stop processing other rules", className: "mb-05rem mr-05rem", checked: actions.stop_processing_others, onChange: (e, { checked }) => {
                        setActions(Object.assign(Object.assign({}, actions), { stop_processing_others: checked }));
                    } }),
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "question circle" }) }, "Toggle this on to stop processing other rules once this rule is triggered.")),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, label: "Delete this rule after use", className: "mb-05rem mr-05rem", checked: actions.one_time_rule, onChange: (e, { checked }) => {
                        setActions(Object.assign(Object.assign({}, actions), { one_time_rule: checked }));
                    } }),
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "question circle" }) }, "Toggle this on to delete this rule once it's been triggered.")),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, label: "Run rule on transaction updates", className: "mb-05rem mr-05rem", checked: conditions.run_on_update, onChange: (e, { checked }) => {
                        setConditions(Object.assign(Object.assign({}, conditions), { run_on_update: checked }));
                    } }),
                React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", name: "question circle" }) }, "Toggle this on to run this rule when a transaction is updated. By default, rules are only run on transaction creation.")),
            React.createElement(semantic_ui_react_1.Form, null,
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("div", null,
                            React.createElement("label", null, "Priority level"),
                            React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", color: "grey", name: "question circle" }) }, "Rules are additive and will be applied in priority order with 20 being lowest and 1 being highest. For example, a matched rule updating the payee name of priority 20 will be overriden by a matched rule updating the payee name of priority 1.")),
                        React.createElement(EditableString_1.default, { identifier: `priority-level`, placeholder: "10", firstValue: conditions.priority, location: 'modal', state: 'Editing', shouldSaveOnChange: true, onSave: (priority) => __awaiter(void 0, void 0, void 0, function* () {
                                setConditions(Object.assign(Object.assign({}, conditions), { priority }));
                            }) }))),
                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                    React.createElement(semantic_ui_react_1.Form.Field, null,
                        React.createElement("label", null, "Rule Description"),
                        React.createElement(semantic_ui_react_1.TextArea, { rows: 2, value: actions.description || '', onChange: (e, { value }) => {
                                setActions(Object.assign(Object.assign({}, actions), { description: value }));
                                setDisableOnClickOutside(true);
                            } }))))),
        (rule === null || rule === void 0 ? void 0 : rule.rule_id) && !(rule === null || rule === void 0 ? void 0 : rule.criteria_suggested) && (React.createElement(semantic_ui_react_1.Button, { fluid: true, className: "mb-05rem", disabled: buttonIsLoading, onClick: () => {
                setRule(null);
                data.setEditRule(null);
                _modal.data.current.rule = null;
                document.getElementById('modal-content').scrollTo(0, 0);
            } }, "Duplicate Rule")),
        (rule === null || rule === void 0 ? void 0 : rule.rule_id) && !(rule === null || rule === void 0 ? void 0 : rule.criteria_suggested) && (React.createElement(semantic_ui_react_1.Button, { color: "red", fluid: true, disabled: buttonIsLoading, onClick: () => {
                setShowDeleteConfirm(true);
            } }, "Delete Rule")),
        showDeleteConfirm && (React.createElement(semantic_ui_react_1.Confirm, { open: !!showDeleteConfirm, onConfirm: () => __awaiter(void 0, void 0, void 0, function* () {
                const criteria_id = rule.rule_criteria_id;
                const results = yield utils._process(rules_1.deleteRule)(criteria_id);
                if (!results.error) {
                    data.deleteCallback('rule', criteria_id);
                    utils._showToast({
                        message: 'Successfully deleted rule',
                        type: 'success',
                    });
                    setShowDeleteConfirm(false);
                    if (_modal.data.current.previous == 'EDIT_RECURRING') {
                        switchView('EDIT_RECURRING');
                    }
                    else {
                        setVisibility(false);
                    }
                }
            }), onCancel: () => {
                setShowDeleteConfirm(false);
            }, confirmButton: 'Delete this rule', cancelButton: 'Cancel', header: `Are you sure you want to delete this rule?`, content: React.createElement("div", { className: "content g-rules-modal" },
                React.createElement("div", { className: "border" }, (0, rules_2.buildMatchesString)(rule, _assets, _categories)),
                React.createElement("p", { className: "center-align" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "arrow alternate circle down" })),
                React.createElement("div", { className: "border" }, (0, rules_2.buildRulesString)({
                    rule,
                    flipSign: _user.settings['show_debits_as_negative'],
                    _categories,
                    _tags,
                }))) }))));
};
exports.default = AddModal;
