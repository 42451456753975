"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.charge = exports.requestCancellation = exports.extendTrial = exports.pauseSubscription = exports.getSubscription = exports.setupIntent = exports.endTrial = exports.setDefaultPaymentMethod = exports.removePaymentMethod = exports.previewPlan = exports.updatePlan = exports.getPortalLink = void 0;
const common_1 = require("./common");
const getPortalLink = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)('billing/portal');
});
exports.getPortalLink = getPortalLink;
const updatePlan = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)('billing/plans', opts);
});
exports.updatePlan = updatePlan;
const previewPlan = (opts) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)('billing/plans/preview', opts);
});
exports.previewPlan = previewPlan;
const removePaymentMethod = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`billing/payment_method`);
});
exports.removePaymentMethod = removePaymentMethod;
const setDefaultPaymentMethod = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`billing/set_default`, { paymentMethodId: id });
});
exports.setDefaultPaymentMethod = setDefaultPaymentMethod;
const endTrial = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`billing/end_trial`);
});
exports.endTrial = endTrial;
const setupIntent = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`billing/setup_intent`);
});
exports.setupIntent = setupIntent;
const getSubscription = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)('billing', {});
});
exports.getSubscription = getSubscription;
const pauseSubscription = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)('billing/pause', {});
});
exports.pauseSubscription = pauseSubscription;
const extendTrial = (extension_id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`billing/extend_trial/${extension_id}`);
});
exports.extendTrial = extendTrial;
const requestCancellation = (obj) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`billing/cancellation`, obj);
});
exports.requestCancellation = requestCancellation;
const charge = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`stripe/charge`, body);
});
exports.charge = charge;
