"use strict";
/**
 * BillingProvider
 * providers data on:
 * - subscription status (trialing, active, past due)
 *
 * This must always be called first before any other endpoint
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingProvider = exports.BillingContext = void 0;
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const billing_1 = require("@actions/billing");
const react_router_dom_1 = require("react-router-dom");
const BillingContext = (0, react_1.createContext)({
    isActive: false,
    isPaused: false,
    isReady: false,
    status: null,
    referralMethod: null,
    trialEnd: null,
    deletion: null,
    subscription: {
        balance: { amount: null, currency: null },
        user_type: null,
        coupon_text: null,
        payment: {
            id: null,
            card: {
                brand: null,
                last4: null,
                exp_month: null,
                exp_year: null,
            },
            sepa_debit: {
                last4: null,
            },
        },
        trial_end: null,
        trial: { end: null },
        plan: {
            id: null,
            amount: 0,
            currency: '',
            type: '',
            quantity: 1,
            interval: '',
        },
        coupon: { duration: null, percent_off: null },
        invoice: { amount_due: null, currency: null, next_payment_attempt: null },
    },
    user: {
        type: null,
        stripe_plan_currency: null,
        stripe_status: null,
        paused: false,
        paused_at: null,
    },
    checkBilling: (derp) => { },
});
exports.BillingContext = BillingContext;
const BillingProvider = props => {
    // Used for EditableCategory
    let history = (0, react_router_dom_1.useHistory)();
    const [subscription, setSubscription] = (0, react_1.useState)(null);
    const [user, setUser] = (0, react_1.useState)(null);
    // When normal flow is ready
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    const [status, setStatus] = (0, react_1.useState)(null);
    const [referralMethod, setReferralMethod] = (0, react_1.useState)(null);
    const [deletion, setDeletion] = (0, react_1.useState)(null);
    const [trialEnd, setTrialEnd] = (0, react_1.useState)(null);
    const [isActive, setIsActive] = (0, react_1.useState)(false);
    const [isPaused, setIsPaused] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        console.log('[Billing Provider] Loaded.');
        checkBilling();
    }, []);
    /**
     * Only do this once.....
     */
    const checkBilling = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (random = false) {
        var _a, _b;
        const results = yield (0, billing_1.getSubscription)();
        try {
            setDeletion(results.scheduled_for_deletion
                ? Moment(results.scheduled_for_deletion, 'YYYY-MM-DD').format('MMM D, YYYY')
                : null);
            setIsPaused(results.user.paused);
            setIsActive(results.user.type == 'early-adopter' ||
                results.user.stripe_status == 'active' ||
                results.user.stripe_status == 'trialing');
            setStatus(results.user.stripe_status); // trialing, past_due, canceled, active
            setTrialEnd(((_a = results.plan) === null || _a === void 0 ? void 0 : _a.trial_end)
                ? Moment(results.plan.trial_end, 'X').format('MMM D, YYYY')
                : null);
            setReferralMethod(results.referral_method);
            setUser(results.user);
            setSubscription(results);
            if (!isReady) {
                if (((_b = results.plan) === null || _b === void 0 ? void 0 : _b.trial_end) &&
                    results.status === 'trialing' &&
                    results.user_type !== 'early-adopter' &&
                    !results.payment_method &&
                    !results.referral_method) {
                    setIsReady(true);
                }
                else {
                    // Only setIsReady if subscription is active
                    setIsReady(true);
                }
            }
            console.log('[Billing Provider] Done fetching billing details.');
        }
        catch (e) {
            console.log('[Billing Provider] Error.', e);
        }
    });
    return (React.createElement(BillingContext.Provider, { value: React.useMemo(() => ({
            isActive,
            isPaused,
            isReady,
            status,
            referralMethod,
            deletion,
            subscription,
            trialEnd,
            checkBilling,
            user,
        }), [
            isActive,
            isPaused,
            isReady,
            status,
            referralMethod,
            deletion,
            subscription,
            trialEnd,
            checkBilling,
            user,
        ]) }, props.children));
};
exports.BillingProvider = BillingProvider;
