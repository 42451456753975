"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const _ = require("lodash");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditableTags_1 = require("@components/elements/EditableTags");
const UserProvider_1 = require("@providers/UserProvider");
const EditableAsset_1 = require("../elements/EditableAsset");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const format_1 = require("@/helpers/format");
const assets_1 = require("@/actions/assets");
var SETTINGS_STEPS;
(function (SETTINGS_STEPS) {
    SETTINGS_STEPS[SETTINGS_STEPS["Review Notations"] = 0] = "Review Notations";
    SETTINGS_STEPS[SETTINGS_STEPS["Review Categories"] = 1] = "Review Categories";
    SETTINGS_STEPS[SETTINGS_STEPS["Review Tags"] = 2] = "Review Tags";
    SETTINGS_STEPS[SETTINGS_STEPS["Review Accounts"] = 3] = "Review Accounts";
    SETTINGS_STEPS[SETTINGS_STEPS["Final Settings"] = 4] = "Final Settings";
})(SETTINGS_STEPS || (SETTINGS_STEPS = {}));
const Settings = ({ data, goNext, goBack, setCurrentConfig, currentConfig, separateCorruptAndGoodData, selectedAccount, startAtLastStep, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const [saveConfig, setSaveConfig] = (0, react_1.useState)(true);
    const [currentStep, setCurrentStep] = (0, react_1.useState)(0);
    const [startIndex, setStartIndex] = (0, react_1.useState)(0);
    const [corrupt, setCorrupt] = (0, react_1.useState)([]);
    const [goodData, setGoodData] = (0, react_1.useState)([]);
    const [dateFormatInput, setDateFormatInput] = (0, react_1.useState)(null);
    const [openDateOverride, setOpenDateOverride] = (0, react_1.useState)(false);
    const [showCorrupt, setShowCorrupt] = (0, react_1.useState)(false);
    const [tagMatches, setTagMatches] = (0, react_1.useState)({});
    const [categoryMatches, setCategoryMatches] = (0, react_1.useState)({});
    const [accountMatches, setAccountMatches] = (0, react_1.useState)({});
    const [showAccountModal, setShowAccountModal] = (0, react_1.useState)(null);
    const [steps, setSteps] = (0, react_1.useState)([]);
    const [buttonIsLoading, setButtonIsLoading] = (0, react_1.useState)(false);
    const [showOptionalAccountSettings, setShowOptionalAccountSettings,] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setButtonIsLoading(false);
        setShowOptionalAccountSettings(false);
        const _steps = [];
        let _currentStep = null;
        _steps.push('Review Notations');
        // Reviewing categories
        if (Object.keys(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._categories).length > 0) {
            _steps.push('Review Categories');
            setCategoryMatches(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._categories);
            if ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.id) &&
                Object.keys(_.pickBy(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._categories, o => !o)).length > 0) {
                // If there are some to create, then we need to start here
                if (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.id) {
                    _currentStep = SETTINGS_STEPS['Review Categories'];
                }
            }
        }
        // Reviewing tags
        if (Object.keys(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._tags).length > 0) {
            _steps.push('Review Tags');
            setTagMatches(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._tags);
            // Are any null?
            if ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.id) &&
                Object.keys(_.pickBy(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._tags, o => !o)).length > 0) {
                // If there are some to create, then we need to start here
                if ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.id) && !_currentStep) {
                    _currentStep = SETTINGS_STEPS['Review Tags'];
                }
            }
        }
        if (Object.keys(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._accounts).length > 0) {
            _steps.push('Review Accounts');
            setAccountMatches(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._accounts);
            // If there are some to create, then we need to start here
            if ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.id) && !_currentStep) {
                _currentStep = SETTINGS_STEPS['Review Accounts'];
            }
        }
        _steps.push('Final Settings');
        setSteps(_steps);
        if (startAtLastStep) {
            setCurrentStep(_steps.length - 1);
        }
        else if (!(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.id)) {
            // If there's no saved config, start at 0
            setCurrentStep(0);
        }
        else if ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.id) && _currentStep == null) {
            const { good, bad } = separateCorruptAndGoodData(data);
            // Go to Review if we're at the last step with nothing to configure, and we're on a saved config
            goNext({
                data: good,
            });
        }
        else if (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.id) {
            // Start in the middle somwhere
            setCurrentStep(_currentStep);
        }
    }, []);
    (0, react_1.useEffect)(() => {
        setCurrentConfig(Object.assign(Object.assign(Object.assign(Object.assign({}, currentConfig), (Object.keys(accountMatches).length > 0
            ? { _accounts: accountMatches }
            : {})), (Object.keys(tagMatches).length > 0 ? { _tags: tagMatches } : {})), (Object.keys(categoryMatches).length > 0
            ? { _categories: categoryMatches }
            : {})));
    }, [tagMatches, categoryMatches, accountMatches]);
    (0, react_1.useEffect)(() => {
        const { good, bad } = separateCorruptAndGoodData(data);
        setGoodData(good);
        setCorrupt(bad);
    }, [currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._date_format]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "p-import-container" },
            React.createElement("div", { className: "left" },
                React.createElement(semantic_ui_react_1.Card, null,
                    React.createElement(semantic_ui_react_1.Card.Content, null,
                        React.createElement(semantic_ui_react_1.Card.Header, null, "Steps")),
                    React.createElement(semantic_ui_react_1.Card.Content, { className: "card-content-wrapper" },
                        React.createElement(semantic_ui_react_1.Progress, { className: "mb-05rem", value: currentStep + 1, total: steps.length, color: 'green', progress: "ratio" }),
                        steps.map((step, index) => {
                            return (React.createElement("div", { className: "card-content", key: `step-${index}` },
                                React.createElement("div", null,
                                    React.createElement(semantic_ui_react_1.Icon, { name: currentStep > index
                                            ? 'check circle'
                                            : 'circle outline', color: currentStep >= index ? 'green' : 'grey' }),
                                    ' ',
                                    React.createElement("span", { className: `card-text ${currentStep >= index ? 'color--green' : ''} ${currentStep >= index ? 'font--bold' : ''}` }, step))));
                        })))),
            React.createElement("div", { className: "right" },
                steps[currentStep] == 'Review Notations' && (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement(semantic_ui_react_1.Message.Header, null, "Check the date and amount notations"),
                        React.createElement("p", null, "Ensure that the transaction dates are being properly formatted and the amount column is properly distinguished between 'credit' and 'debit'. If either are incorrect, use the buttons 'Fix Dates' and 'Flip Types' to adjust accordingly."),
                        React.createElement("p", null,
                            React.createElement("b", null, "Is your amount notation incorrect?"),
                            " Lunch Money supports multiple ways to import the amount column, such as a double column format (two columns denoting inflow and outflow) or by transaction type (one single column denoting debit/credit). Hit 'back' to re-assign the appropriate columns to see these changes.")),
                    React.createElement(semantic_ui_react_1.Table, { compact: true, unstackable: true, selectable: true, celled: true, className: "csv-settings-table" },
                        React.createElement(semantic_ui_react_1.Table.Header, null,
                            React.createElement(semantic_ui_react_1.Table.Row, null,
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align", colSpan: 3 },
                                    React.createElement("div", null, "Date")),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align" },
                                    React.createElement("div", null, "Payee")),
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align", colSpan: 3 },
                                    React.createElement("div", null, "Amount")))),
                        React.createElement(semantic_ui_react_1.Table.Body, null,
                            [
                                ...data
                                    .filter(o => o.amount < 0)
                                    .slice(startIndex, startIndex + 5),
                                ...data
                                    .filter(o => o.amount > 0)
                                    .slice(startIndex, startIndex + 5),
                            ].map((tx, index) => {
                                return (React.createElement(semantic_ui_react_1.Table.Row, { key: `good-data-${index}` },
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align table-cell-date-old" },
                                        React.createElement("span", null, tx.original_date)),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align table-cell-arrow" },
                                        React.createElement("span", null,
                                            React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" }))),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "table-cell-date-new" },
                                        React.createElement("span", { className: Moment(tx.original_date, (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._date_format) || null).isValid()
                                                ? 'color--brand-green'
                                                : 'color--red' },
                                            Moment(tx.original_date, (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._date_format) || null).format(_user.getMonthDayYearFormat()),
                                            !Moment(tx.original_date, (currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._date_format) || null).isValid() && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "warning sign", color: "red", className: "ml-05rem" }), inverted: true, size: "small" },
                                                React.createElement("p", null, "We are not able to properly parse this date. Use the 'Fix Dates' button to set the proper date format. If this is not the proper date, column click 'Back' to re-assign columns."),
                                                React.createElement("p", null, "If this is not fixed, this row will not be imported."))))),
                                    React.createElement(semantic_ui_react_1.Table.Cell, null, tx.payee),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align table-cell-price" },
                                        React.createElement(EditablePrice_1.default, { identifier: `price-${tx.id}`, amount: tx.amount * ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._amount_flip) ? -1 : 1), currency: tx.currency, state: 'ReadOnly', showSignLabel: true, isSplit: !!tx.parent_id, isGrouped: tx.is_group, isPartOfGroup: !!tx.group_id, isRecurring: !!tx.recurring_id, onSave: null, location: null })),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "table-cell-arrow center-align" },
                                        React.createElement("span", null,
                                            React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" }))),
                                    React.createElement(semantic_ui_react_1.Table.Cell, { className: "table-cell-price-type" },
                                        !_user.settings['show_debits_as_negative'] &&
                                            tx.amount * ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._amount_flip) ? -1 : 1) <
                                                0 && (React.createElement("span", { className: 'color--green' }, "credit")),
                                        !_user.settings['show_debits_as_negative'] &&
                                            tx.amount * ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._amount_flip) ? -1 : 1) >
                                                0 && React.createElement("span", { className: 'color--red' }, "debit"),
                                        _user.settings['show_debits_as_negative'] &&
                                            tx.amount * ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._amount_flip) ? -1 : 1) >
                                                0 && React.createElement("span", { className: 'color--red' }, "debit"),
                                        _user.settings['show_debits_as_negative'] &&
                                            tx.amount * ((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._amount_flip) ? -1 : 1) <
                                                0 && (React.createElement("span", { className: 'color--green' }, "credit")))));
                            }),
                            React.createElement(semantic_ui_react_1.Table.Row, null,
                                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 3 },
                                    React.createElement(semantic_ui_react_1.Popup, { hoverable: true, open: openDateOverride, position: 'bottom center', trigger: React.createElement(semantic_ui_react_1.Button, { fluid: true, color: "orange", basic: true, size: 'small', onClick: () => {
                                                setOpenDateOverride(!openDateOverride);
                                            } }, "Fix dates") },
                                        React.createElement(semantic_ui_react_1.Form, null,
                                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                                    React.createElement("p", { className: "mt-05rem" },
                                                        "Using ",
                                                        React.createElement("b", null, "YYYY"),
                                                        ", ",
                                                        React.createElement("b", null, "MM"),
                                                        ", ",
                                                        React.createElement("b", null, "DD"),
                                                        " and the proper delimiter (usually ",
                                                        React.createElement("b", null, "-"),
                                                        " or ",
                                                        React.createElement("b", null, "/"),
                                                        "), what format is the date in from your CSV?"),
                                                    React.createElement(semantic_ui_react_1.Input, { placeholder: 'e.g. DD/MM/YYYY', onChange: (e, { value }) => {
                                                            if (!!value) {
                                                                setDateFormatInput(value);
                                                            }
                                                        }, action: React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                                                                setCurrentConfig(Object.assign(Object.assign({}, currentConfig), { _date_format: dateFormatInput }));
                                                                setOpenDateOverride(false);
                                                            } }, "Set") })))),
                                        React.createElement("span", { className: "link clickable", onClick: () => {
                                                setOpenDateOverride(false);
                                            } }, "Close"))),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, data.length > 5 && (React.createElement(semantic_ui_react_1.Button, { fluid: true, basic: true, color: "grey", size: 'small', onClick: () => {
                                        if (startIndex + 5 >= data.length) {
                                            setStartIndex(0);
                                        }
                                        else {
                                            setStartIndex(startIndex + 5);
                                        }
                                    } }, startIndex + 5 >= data.length
                                    ? 'Show previous'
                                    : 'Show next'))),
                                React.createElement(semantic_ui_react_1.Table.Cell, { colSpan: 3 },
                                    React.createElement(semantic_ui_react_1.Button, { fluid: true, color: "orange", basic: true, size: 'small', onClick: () => {
                                            setCurrentConfig(Object.assign(Object.assign({}, currentConfig), { _amount_flip: !currentConfig['_amount_flip'] }));
                                        } }, "Flip types"))))))),
                steps[currentStep] == 'Review Categories' && (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement(semantic_ui_react_1.Message.Header, null, "Match your categories"),
                        React.createElement("p", null, "Looks like we're importing some new categories along with the transactions! Please take a moment to match your imported categories to your Lunch Money categories or create new categories."),
                        React.createElement(semantic_ui_react_1.Button, { className: "mb-05rem", size: "mini", icon: true, labelPosition: 'left', onClick: () => {
                                window.open('https://support.lunchmoney.app/migrating-from-mint#review-categories');
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                            "View documentation on reviewing categories")),
                    React.createElement(semantic_ui_react_1.Table, { compact: true, unstackable: true, selectable: true, celled: true, className: "csv-settings-table" },
                        React.createElement(semantic_ui_react_1.Table.Header, null,
                            React.createElement(semantic_ui_react_1.Table.Row, null,
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align", colSpan: 3 },
                                    React.createElement("div", null, "Category Matching")))),
                        React.createElement(semantic_ui_react_1.Table.Body, null, Object.keys(categoryMatches).map((category, index) => {
                            return (React.createElement(semantic_ui_react_1.Table.Row, { key: `category-${index}` },
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align table-cell-date-old" },
                                    React.createElement("span", null, category)),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align table-cell-arrow" },
                                    React.createElement("span", null,
                                        React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" }))),
                                React.createElement(semantic_ui_react_1.Table.Cell, null,
                                    React.createElement(EditableCategory_1.default, { className: "unwrap", identifier: `bulk-edit-category`, showUncategorized: true, firstValue: categoryMatches[category] || undefined, placeholder: `Type to search or add new. Leave blank to create a new category, ${category.slice(0, 100)}`, extraValues: [
                                            {
                                                key: 'new',
                                                value: 'new',
                                                text: `Create New Category: ${category.slice(0, 100)}`,
                                            },
                                        ], state: 'Editing', location: 'modal', allowSelectingGroups: false, onSave: (value) => __awaiter(void 0, void 0, void 0, function* () {
                                            setCategoryMatches(Object.assign(Object.assign({}, categoryMatches), { [category]: value == null ? -1 : value }));
                                        }) }))));
                        }))))),
                steps[currentStep] == 'Review Tags' && (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement(semantic_ui_react_1.Message.Header, null, "Match your tags"),
                        React.createElement("p", null, "Looks like we're importing some tags along with the transactions! Please take a moment to match your imported tags to your Lunch Money tags or create new tags."),
                        React.createElement(semantic_ui_react_1.Button, { className: "mb-05rem", size: "mini", icon: true, labelPosition: 'left', onClick: () => {
                                window.open('https://support.lunchmoney.app/migrating-from-mint#review-tags');
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                            "View documentation on reviewing tags")),
                    React.createElement(semantic_ui_react_1.Table, { compact: true, unstackable: true, selectable: true, celled: true, className: "csv-settings-table" },
                        React.createElement(semantic_ui_react_1.Table.Header, null,
                            React.createElement(semantic_ui_react_1.Table.Row, null,
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align", colSpan: 3 },
                                    React.createElement("div", null, "Tag Matching")))),
                        React.createElement(semantic_ui_react_1.Table.Body, null, Object.keys(tagMatches)
                            .filter(o => !!o)
                            .map((tag, index) => {
                            return (React.createElement(semantic_ui_react_1.Table.Row, { key: `category-${index}` },
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align table-cell-date-old" },
                                    React.createElement("span", null, tag)),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align table-cell-arrow" },
                                    React.createElement("span", null,
                                        React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" }))),
                                React.createElement(semantic_ui_react_1.Table.Cell, null,
                                    React.createElement(EditableTags_1.default, { allowAdd: true, multiple: false, initialValue: [tagMatches[tag] || null], showUntagged: true, placeholder: `Type to search or create new. Leave blank to create a new tag, ${tag}.`, onChange: (value) => __awaiter(void 0, void 0, void 0, function* () {
                                            setTagMatches(Object.assign(Object.assign({}, tagMatches), { [tag]: value }));
                                        }) }))));
                        }))))),
                steps[currentStep] == 'Review Accounts' && (React.createElement(React.Fragment, null,
                    Object.keys(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._accounts).length == 1 ? (React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement(semantic_ui_react_1.Message.Header, null, "Which account should we import these transactions into?"),
                        React.createElement("p", null, "You can either import them into a default Cash account, select an existing account or create a new one below."))) : (React.createElement(semantic_ui_react_1.Message, { info: true },
                        React.createElement(semantic_ui_react_1.Message.Header, null, "Match your accounts"),
                        React.createElement("p", null, "Looks like we're importing some accounts along with the transactions! Please take a moment to match your imported accounts to your Lunch Money accounts or create new accounts."),
                        React.createElement(semantic_ui_react_1.Button, { className: "mb-05rem", size: "mini", icon: true, labelPosition: 'left', onClick: () => {
                                window.open('https://support.lunchmoney.app/migrating-from-mint#review-accounts');
                            } },
                            React.createElement(semantic_ui_react_1.Icon, { name: "external alternate" }),
                            "View documentation on reviewing accounts"))),
                    React.createElement(semantic_ui_react_1.Table, { compact: true, unstackable: true, selectable: true, celled: true, className: "csv-settings-table" },
                        React.createElement(semantic_ui_react_1.Table.Header, null,
                            React.createElement(semantic_ui_react_1.Table.Row, null,
                                React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "center-align", colSpan: 3 },
                                    React.createElement("div", null, "Account Matching")))),
                        React.createElement(semantic_ui_react_1.Table.Body, null, Object.keys(accountMatches).map((account, index) => {
                            var _a, _b, _c, _d, _e;
                            return (React.createElement(semantic_ui_react_1.Table.Row, { key: `category-${index}` },
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "right-align table-cell-date-old" },
                                    React.createElement("span", null, account)),
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "center-align table-cell-arrow" },
                                    React.createElement("span", null,
                                        React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" }))),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, ((_a = accountMatches[account]) === null || _a === void 0 ? void 0 : _a.name) ? (React.createElement("div", { className: "flex--space-between" },
                                    React.createElement("b", null,
                                        "Create new:",
                                        ' ', (_b = accountMatches[account]) === null || _b === void 0 ? void 0 :
                                        _b.institution_name,
                                        ' ', (_c = accountMatches[account]) === null || _c === void 0 ? void 0 :
                                        _c.name,
                                        ' ',
                                        ((_d = accountMatches[account]) === null || _d === void 0 ? void 0 : _d.type_name) &&
                                            `(${(_e = accountMatches[account]) === null || _e === void 0 ? void 0 : _e.type_name})`),
                                    React.createElement("div", { className: "display--flex" },
                                        React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", onClick: () => {
                                                // open modal
                                                setShowAccountModal(account);
                                            } }, "Edit"),
                                        React.createElement(semantic_ui_react_1.Button, { basic: true, color: "red", onClick: () => {
                                                setAccountMatches(Object.assign(Object.assign({}, accountMatches), { [account]: null }));
                                            } }, "Remove")))) : (React.createElement("div", { className: "flex--center" },
                                    React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                                            var _a;
                                            if (accountMatches[account] == null) {
                                                const _accountMatch = Object.assign(Object.assign({}, accountMatches), { [account]: {
                                                        name: account,
                                                        type_name: ['mastercard', 'visa', 'amex'].reduce((acc, substr) => acc !== -1
                                                            ? acc
                                                            : account
                                                                .toLowerCase()
                                                                .indexOf(substr), -1) > -1
                                                            ? 'credit'
                                                            : [
                                                                'chequing',
                                                                'checking',
                                                                'savings',
                                                                'cash',
                                                            ].reduce((acc, substr) => acc !== -1
                                                                ? acc
                                                                : account
                                                                    .toLowerCase()
                                                                    .indexOf(substr), -1) > -1
                                                                ? 'cash'
                                                                : [
                                                                    'ira',
                                                                    'invest',
                                                                    'brokerage',
                                                                    'money market',
                                                                ].reduce((acc, substr) => acc !== -1
                                                                    ? acc
                                                                    : account
                                                                        .toLowerCase()
                                                                        .indexOf(substr), -1) > -1
                                                                    ? 'investment'
                                                                    : [
                                                                        'loan',
                                                                        'mortgage',
                                                                        'brokerage',
                                                                    ].reduce((acc, substr) => acc !== -1
                                                                        ? acc
                                                                        : account
                                                                            .toLowerCase()
                                                                            .indexOf(substr), -1) > -1
                                                                        ? 'loan'
                                                                        : null,
                                                    } });
                                                setAccountMatches(_accountMatch);
                                                if (((_a = _accountMatch[account]) === null || _a === void 0 ? void 0 : _a.type_name) == null) {
                                                    setShowAccountModal(account);
                                                }
                                            }
                                        } }, "Create New Account"),
                                    React.createElement("span", { className: "ml-05rem mr-05rem" }, "or"),
                                    React.createElement(EditableAsset_1.default, { defaultSelection: accountMatches[account], placeholder: 'Leave blank to assign to default Cash account (not recommended)', onSave: (value) => __awaiter(void 0, void 0, void 0, function* () {
                                            setAccountMatches(Object.assign(Object.assign({}, accountMatches), { [account]: value }));
                                        }) }))))));
                        }))))),
                steps[currentStep] == 'Final Settings' && (React.createElement(React.Fragment, null,
                    corrupt.length > 0 && (React.createElement(React.Fragment, null,
                        React.createElement(semantic_ui_react_1.Message, { warning: true, className: showCorrupt ? '' : 'clickable' },
                            React.createElement(semantic_ui_react_1.Message.Header, null,
                                "We found ",
                                corrupt.length,
                                " malformed lines in your CSV. These will not be imported.",
                                ' ',
                                React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", color: "grey" }), inverted: true }, "If the date format is incorrect, causing rows to appear malformed, go back to the 'Review Notations' step and use the \"Fix Dates\" button.")),
                            React.createElement(semantic_ui_react_1.Button, { className: "mt-1rem", onClick: () => {
                                    setShowCorrupt(!showCorrupt);
                                }, size: "mini" },
                                showCorrupt ? 'Hide' : 'View',
                                " malformed lines")),
                        showCorrupt && (React.createElement(semantic_ui_react_1.Table, { compact: true, fixed: true, unstackable: true, selectable: true, celled: true, style: { marginTop: '0px' } },
                            React.createElement(semantic_ui_react_1.Table.Header, null,
                                React.createElement(semantic_ui_react_1.Table.Row, null,
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                        React.createElement("b", null, "Line Number")),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                        React.createElement("b", null, "Date")),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                        React.createElement("b", null, "Payee")),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                        React.createElement("b", null, "Amount")),
                                    React.createElement(semantic_ui_react_1.Table.HeaderCell, null,
                                        React.createElement("b", null, "Notes")))),
                            React.createElement(semantic_ui_react_1.Table.Body, null, corrupt.map((tx, index) => {
                                return (React.createElement(semantic_ui_react_1.Table.Row, { key: `corrupt-line-${index}` },
                                    React.createElement(semantic_ui_react_1.Table.Cell, null,
                                        React.createElement(semantic_ui_react_1.Icon, { name: "remove circle", color: "red" }),
                                        " Line",
                                        ' ',
                                        tx.id),
                                    React.createElement(semantic_ui_react_1.Table.Cell, null, tx.original_date || (React.createElement("span", { className: "color--grey no-padding" }, "No data"))),
                                    React.createElement(semantic_ui_react_1.Table.Cell, null, tx.payee || (React.createElement("span", { className: "color--grey no-padding" }, "No data"))),
                                    React.createElement(semantic_ui_react_1.Table.Cell, null, tx.amount == null || isNaN(tx.amount) ? (React.createElement("span", { className: "color--grey no-padding" }, "No data")) : (tx.amount)),
                                    React.createElement(semantic_ui_react_1.Table.Cell, null, tx.notes || (React.createElement("span", { className: "color--grey no-padding" }, "No data")))));
                            })))))),
                    React.createElement(semantic_ui_react_1.Card, { fluid: true },
                        React.createElement(semantic_ui_react_1.Card.Content, null,
                            React.createElement(semantic_ui_react_1.Card.Header, null, "Other Settings")),
                        React.createElement(semantic_ui_react_1.Card.Content, null,
                            React.createElement(semantic_ui_react_1.Form, { className: "mb-0" },
                                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._apply_category_rules, onChange: (e, { checked }) => {
                                                setCurrentConfig(Object.assign(Object.assign({}, currentConfig), { _apply_category_rules: checked }));
                                            }, label: "Apply rules (Recommended)" }),
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle", color: "grey" }) },
                                            React.createElement("p", null, "Toggle this on if you would like to apply your Rules to your imported transactions.")))),
                                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._skip_duplicates, onChange: (e, { checked }) => {
                                                setCurrentConfig(Object.assign(Object.assign({}, currentConfig), { _skip_duplicates: checked }));
                                            }, label: "Skip duplicates (Recommended)" }),
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle", color: "grey" }) },
                                            React.createElement("p", null, "If you have an existing transaction with the same date, payee name and amount, we will assume this is a duplicate transaction and we will not import it.")))),
                                (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.id) && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-05rem" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement(semantic_ui_react_1.Checkbox, { toggle: true, checked: saveConfig, onChange: (e, { checked }) => {
                                                setSaveConfig(checked);
                                            }, label: "Save configuration for next time (Recommended)" }),
                                        ' ',
                                        React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle", color: "grey" }) },
                                            React.createElement("p", null, "Your settings here will be saved as the default configuration for this account so next time you won't have to go through these steps.")))))))))),
                React.createElement("div", { className: "flex--space-between" },
                    React.createElement(semantic_ui_react_1.Button, { icon: true, labelPosition: "left", onClick: () => {
                            if (currentStep > 0) {
                                setCurrentStep(currentStep - 1);
                            }
                            else {
                                goBack();
                            }
                        } },
                        React.createElement(semantic_ui_react_1.Icon, { name: "arrow left" }),
                        "Back"),
                    React.createElement("div", { className: "display--flex" },
                        React.createElement(semantic_ui_react_1.Button, { disabled: corrupt.length == data.length, loading: buttonIsLoading, icon: true, labelPosition: "right", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                if (currentStep + 1 < steps.length) {
                                    setCurrentStep(currentStep + 1);
                                }
                                else {
                                    setButtonIsLoading(true);
                                    if ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.id) && saveConfig) {
                                        yield (0, assets_1.saveImportConfig)({
                                            currency: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._currency,
                                            indices: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._indices,
                                            // amountType: useAmountFlow, // deprecated
                                            amountFlip: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._amount_flip,
                                            applyCategoryRules: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._apply_category_rules,
                                            skipDuplicates: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._skip_duplicates,
                                            dateFormat: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._date_format,
                                            delimiter: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._delimiter,
                                            encoding: currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._encoding,
                                            categoryMatches: Object.assign(Object.assign({}, currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._originalCategories), currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._categories),
                                            tagMatches: Object.assign(Object.assign({}, currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._originalTags), currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig._tags),
                                            [selectedAccount.source == 'manual'
                                                ? 'asset_id'
                                                : 'plaid_account_id']: selectedAccount.id > 0 ? selectedAccount.id : null,
                                        });
                                    }
                                    // for good measure
                                    const { good, bad } = separateCorruptAndGoodData(goodData);
                                    goNext({
                                        data: good,
                                        tagMatches,
                                        categoryMatches,
                                        accountMatches,
                                    });
                                }
                                window.scrollTo(0, 0);
                            }) },
                            currentStep == steps.length - 1
                                ? 'Review transactions'
                                : `Next: ${steps[currentStep + 1]}`,
                            React.createElement(semantic_ui_react_1.Icon, { name: "arrow right" })))))),
        React.createElement(semantic_ui_react_1.Modal, { size: "tiny", open: !!showAccountModal },
            React.createElement(semantic_ui_react_1.Modal.Header, null,
                "Create a new account: ",
                showAccountModal),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Account Name"),
                            React.createElement(semantic_ui_react_1.Input, { value: (_a = accountMatches[showAccountModal]) === null || _a === void 0 ? void 0 : _a.name, onChange: (e, { value }) => {
                                    setAccountMatches(Object.assign(Object.assign({}, accountMatches), { [showAccountModal]: Object.assign(Object.assign({}, accountMatches[showAccountModal]), { name: value }) }));
                                } }))),
                    React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                        React.createElement(semantic_ui_react_1.Form.Field, null,
                            React.createElement("label", null, "Account Type"),
                            React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", selection: true, value: (_b = accountMatches[showAccountModal]) === null || _b === void 0 ? void 0 : _b.type_name, fluid: true, options: _assets.types.map(o => {
                                    return {
                                        key: o.id,
                                        value: o.name,
                                        text: (0, format_1.capitalize)(o.name),
                                    };
                                }), onChange: (e, { value }) => {
                                    setAccountMatches(Object.assign(Object.assign({}, accountMatches), { [showAccountModal]: Object.assign(Object.assign({}, accountMatches[showAccountModal]), { type_name: value }) }));
                                } }))),
                    React.createElement(semantic_ui_react_1.Accordion, { className: "mb-1rem" },
                        React.createElement(semantic_ui_react_1.Accordion.Title, { index: 0, active: showOptionalAccountSettings, onClick: () => {
                                setShowOptionalAccountSettings(!showOptionalAccountSettings);
                            } },
                            React.createElement("span", { className: "label-style" },
                                React.createElement(semantic_ui_react_1.Icon, { name: "dropdown" }),
                                "Optional fields")),
                        React.createElement(semantic_ui_react_1.Accordion.Content, { active: showOptionalAccountSettings },
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Institution Name (optional)"),
                                    React.createElement(semantic_ui_react_1.Input, { value: (_c = accountMatches[showAccountModal]) === null || _c === void 0 ? void 0 : _c.institution_name, onChange: (e, { value }) => {
                                            setAccountMatches(Object.assign(Object.assign({}, accountMatches), { [showAccountModal]: Object.assign(Object.assign({}, accountMatches[showAccountModal]), { institution_name: value }) }));
                                        } }))),
                            ((_d = accountMatches[showAccountModal]) === null || _d === void 0 ? void 0 : _d.type_name) && (React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null, "Account Subtype (optional)"),
                                    React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", selection: true, fluid: true, options: (_e = _assets.subtypes[accountMatches[showAccountModal]['type_name']]) === null || _e === void 0 ? void 0 : _e.map(o => {
                                            return {
                                                key: o.subtype_id,
                                                value: o.subtype_name,
                                                text: (0, format_1.capitalize)(o.subtype_name),
                                            };
                                        }), onChange: (e, { value }) => {
                                            setAccountMatches(Object.assign(Object.assign({}, accountMatches), { [showAccountModal]: Object.assign(Object.assign({}, accountMatches[showAccountModal]), { subtype_name: value }) }));
                                        } }))))))),
                React.createElement(semantic_ui_react_1.Message, { info: true },
                    React.createElement("p", null,
                        "You can turn this into an automatically synced account with your bank later!",
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "info circle" }), inverted: true, size: "small" },
                            React.createElement("p", null, "Are you currently uploading historical transactions into an account you'd like automatically synced for future transactions?"),
                            React.createElement("p", null,
                                "For more details, see the following knowledge base article:",
                                ' ',
                                React.createElement("a", { className: "link clickable", href: "https://support.lunchmoney.app/importing-transactions/automatic-imports#im-coming-from-another-app-and-i-have-a-csv-file-of-my-historical-transactions-for-an-account-i-woul", target: "_blank" }, "I'm coming from another app and I have a CSV file of my historical transactions for an account I would like automatically synced. How should I approach this?")))))),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { basic: true, color: "orange", onClick: () => {
                        setAccountMatches(Object.assign(Object.assign({}, accountMatches), { [showAccountModal]: null }));
                        setShowAccountModal(null);
                    } }, "Cancel"),
                React.createElement(semantic_ui_react_1.Button, { disabled: ((_f = accountMatches[showAccountModal]) === null || _f === void 0 ? void 0 : _f.name) &&
                        ((_g = accountMatches[showAccountModal]) === null || _g === void 0 ? void 0 : _g.type_name)
                        ? false
                        : true, icon: true, labelPosition: "right", onClick: () => {
                        setShowAccountModal(null);
                    } },
                    "Assign",
                    React.createElement(semantic_ui_react_1.Icon, { name: "check" }))))));
};
exports.default = Settings;
