"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadFile = exports.getFileUrl = exports.uploadPDF = exports.uploadFile = exports.deleteFile = exports.updateFile = void 0;
const common_1 = require("./common");
const updateFile = (file_id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.put)(`transactions/file/${file_id}`, body);
});
exports.updateFile = updateFile;
const deleteFile = (file_id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.del)(`transactions/file/${file_id}`);
});
exports.deleteFile = deleteFile;
const uploadFile = (id, body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`transactions/file/${id}`, body);
});
exports.uploadFile = uploadFile;
const uploadPDF = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.post)(`transactions/file/pdf`, body);
});
exports.uploadPDF = uploadPDF;
const getFileUrl = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`transactions/file/${id}`, {});
});
exports.getFileUrl = getFileUrl;
const downloadFile = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield (0, common_1.get)(`transactions/file/${id}/download`, {});
});
exports.downloadFile = downloadFile;
