"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const CopyCommand_1 = require("@components/elements/CopyCommand");
const Status = ({ status, _showToast }) => {
    return !!(status === null || status === void 0 ? void 0 : status.account_id) ? (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
        React.createElement(semantic_ui_react_1.Card.Content, null,
            React.createElement(semantic_ui_react_1.Card.Header, { className: "flex--space-between" },
                React.createElement("span", null),
                React.createElement("span", null, "Details"),
                React.createElement(semantic_ui_react_1.Label, { circular: true, color: 'teal', empty: true, key: 'teal' }))),
        React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" },
            React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true },
                React.createElement(semantic_ui_react_1.Table.Body, null,
                    status.hasOwnProperty('healthy') && (React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement("b", null, "Status")),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, status.healthy ? (React.createElement("span", { className: "color--green" }, "Healthy")) : (React.createElement("span", { className: "color--red" }, "Error"))))),
                    status.account_id && (React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement("b", null, "Account ID")),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" }, status.account_id))),
                    status.access_token && (React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement("b", null, "Access token")),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                            status.access_token,
                            ' ',
                            React.createElement(CopyCommand_1.default, { value: status.access_token, tooltipText: 'Copy access token', _showToast: _showToast })))),
                    status.plaid_account_id && (React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement("b", null, "Plaid Account ID")),
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                            status.plaid_account_id,
                            ' ',
                            React.createElement(CopyCommand_1.default, { value: status.plaid_account_id, tooltipText: 'Copy plaid_account_id', _showToast: _showToast })))),
                    status.institution_name && (React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                            React.createElement("b", null, "Institution Name")),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, status.institution_name))),
                    status.institution_id && (React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                            React.createElement("b", null, "Institution ID")),
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            status.institution_id,
                            ' ',
                            React.createElement(CopyCommand_1.default, { value: status.institution_id, tooltipText: 'Copy institution id', _showToast: _showToast })))),
                    status.plaid_error && (React.createElement(React.Fragment, null,
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "Error Code")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                "[",
                                status.plaid_error.error_type,
                                "]",
                                ' ',
                                status.plaid_error.error_code)),
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "Error Message")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, status.plaid_error.error_message)),
                        status.plaid_error.display_message && (React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "Display Message")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, status.plaid_error.display_message))),
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "Request ID")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, status.plaid_error.request_id)),
                        status.plaid_error.suggested_action && (React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                React.createElement("b", null, "Suggested Action")),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, status.plaid_error.suggested_action)))))))))) : (React.createElement(React.Fragment, null));
};
exports.default = Status;
