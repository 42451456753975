"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const format_1 = require("@/helpers/format");
const Assign = ({ rawData, setIndexValue, indices, goBack, goNext, hasSelectedAccount, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(semantic_ui_react_1.Message, { info: true },
            React.createElement(semantic_ui_react_1.Message.Header, null, "Match CSV columns"),
            React.createElement("p", null, "We need to match the column data. Use the dropdowns to correspond columns from your CSV file to the appropriate fields Lunch Money needs.")),
        React.createElement("div", { style: { overflowX: 'scroll', marginBottom: '2rem' } },
            React.createElement(semantic_ui_react_1.Table, { unstackable: true, className: "p-csv-import-table flexible" },
                React.createElement(semantic_ui_react_1.Table.Body, null,
                    React.createElement(semantic_ui_react_1.Table.Row, null, rawData === null || rawData === void 0 ? void 0 : rawData.find(row => {
                        return row.length >= 3;
                    }).map((cell, index) => {
                        var _a;
                        return (React.createElement(semantic_ui_react_1.Table.Cell, { key: `header-${index}` },
                            React.createElement(semantic_ui_react_1.Dropdown, { value: ((_a = indices === null || indices === void 0 ? void 0 : indices.find(o => o.index == index)) === null || _a === void 0 ? void 0 : _a.column) || null, selection: true, onChange: (e, { value }) => {
                                    setIndexValue(index, value);
                                }, placeholder: "Do not import", options: [
                                    {
                                        key: 'do-not-import',
                                        value: null,
                                        text: 'Do not import',
                                    },
                                    ...[
                                        'date',
                                        'payee',
                                        'category',
                                        'amount',
                                        'type',
                                        'inflow',
                                        'outflow',
                                        'currency',
                                        hasSelectedAccount ? null : 'account',
                                        'notes',
                                        'tags',
                                        'original_name',
                                    ]
                                        .filter(o => !!o)
                                        .map(o => {
                                        return {
                                            className: 'flex--align-center',
                                            icon: (indices === null || indices === void 0 ? void 0 : indices.find(idx => idx.column == o))
                                                ? 'check'
                                                : null,
                                            label: ['date', 'payee', 'amount'].indexOf(o) > -1
                                                ? 'Required'
                                                : null,
                                            key: o,
                                            value: o,
                                            text: o == 'type'
                                                ? 'Amount Type'
                                                : o == 'inflow' || o == 'outflow'
                                                    ? `${o} (amount)`
                                                    : (0, format_1.capitalize)(o.replace('_', ' ')),
                                        };
                                    }),
                                ] })));
                    }))),
                React.createElement(semantic_ui_react_1.Table.Body, null, rawData === null || rawData === void 0 ? void 0 : rawData.slice(0, 10).map((row, i) => {
                    return (React.createElement(semantic_ui_react_1.Table.Row, { key: `row-${i}` }, row.map((cell, j) => {
                        return (React.createElement(semantic_ui_react_1.Table.Cell, { key: `cell-${i}-${j}` }, cell));
                    })));
                })))),
        React.createElement("div", { className: "flex--space-between" },
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Button, { content: "Start Over", icon: "left arrow", labelPosition: "left", onClick: () => {
                        goBack();
                    } }),
                React.createElement(semantic_ui_react_1.Button, { disabled: 
                    // Disable if there isn't both payee and date
                    (indices === null || indices === void 0 ? void 0 : indices.filter(o => o.column == 'payee' || o.column == 'date').length) !== 2 ||
                        // Disable if there isn't an amount column AND there isn't both inflow/outflow
                        (!indices.find(o => o.column == 'amount') &&
                            (indices === null || indices === void 0 ? void 0 : indices.filter(o => o.column == 'inflow' || o.column == 'outflow').length) !== 2), content: "Next: Column Settings", icon: "right arrow", labelPosition: "right", onClick: () => {
                        goNext();
                    } })))));
};
exports.default = Assign;
