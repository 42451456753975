"use strict";
/**
 *  Admin.tsx
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Moment = require("moment");
const EditableString_1 = require("@components/elements/EditableString");
const EditableDate_1 = require("@components/elements/EditableDate");
const Status_1 = require("@components/Admin/Status");
const UserInfo_1 = require("@components/Admin/UserInfo");
const format_1 = require("@helpers/format");
const CopyCommand_1 = require("@components/elements/CopyCommand");
const admin_1 = require("@actions/admin");
const UserProvider_1 = require("@providers/UserProvider");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const ContainerHeader_1 = require("@components/elements/ContainerHeader");
const Admin = ({ _process, _showToast }) => {
    // Results
    const [accessTokenStatus, setAccessTokenStatus] = (0, react_1.useState)(null);
    const [allAccounts, setAllAccounts] = (0, react_1.useState)(null);
    const [transactions, setTransactions] = (0, react_1.useState)(null);
    const [errorLogs, setErrorLogs] = (0, react_1.useState)(null);
    const [plaidLogs, setPlaidLogs] = (0, react_1.useState)(null);
    const [crypto, setCrypto] = (0, react_1.useState)(false);
    const [betaEmail, setBetaEmail] = (0, react_1.useState)('');
    const [cancelEmail, setCancelEmail] = (0, react_1.useState)('');
    const [deleteId, setDeleteId] = (0, react_1.useState)('');
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [userId, setUserId] = (0, react_1.useState)('');
    const [user, setUser] = (0, react_1.useState)(null);
    const [accounts, setAccounts] = (0, react_1.useState)(null);
    const [plaidAccounts, setPlaidAccounts] = (0, react_1.useState)(null);
    const [email, setEmail] = (0, react_1.useState)('');
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    // Email
    const [emailUserId, setEmailUserId] = (0, react_1.useState)('');
    const [emailTemplate, setEmailTemplate] = (0, react_1.useState)('');
    const [emailAccountId, setEmailAccountId] = (0, react_1.useState)('');
    const [emailData, setEmailData] = (0, react_1.useState)('');
    // Input
    const [plaidAccountId, setPlaidAccountId] = (0, react_1.useState)('');
    const [accessToken, setAccessToken] = (0, react_1.useState)(undefined);
    const [triggerRefresh, setTriggerRefresh] = (0, react_1.useState)(false);
    const [startDate, setStartDate] = (0, react_1.useState)(Moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD'));
    const [endDate, setEndDate] = (0, react_1.useState)(Moment().format('YYYY-MM-DD'));
    const toReadableDateSpan = date => {
        if (!date) {
            return React.createElement("span", { className: "color--red" }, "Never");
        }
        else {
            return (React.createElement(semantic_ui_react_1.Popup, { inverted: true, size: "small", position: "top center", trigger: React.createElement("span", null, Moment(date).fromNow()) }, Moment(date).format('MMMM D, YYYY [at] h:mm a')));
        }
    };
    const clearUserInput = () => {
        setUserId('');
        setEmail('');
    };
    const clearUserDetails = () => {
        setErrorLogs(null);
        setPlaidLogs(null);
        setAllAccounts(null);
        setAccounts(null);
        setAccessTokenStatus(Object.assign(Object.assign({}, accessTokenStatus), { user: null }));
    };
    const clearAccountInput = () => {
        setPlaidAccountId('');
        setAccessToken('');
    };
    const clearAccountsDetails = () => {
        setAccessTokenStatus(null);
        setPlaidAccounts(null);
        setTransactions(null);
    };
    const getUserDetails = () => __awaiter(void 0, void 0, void 0, function* () {
        clearAccountsDetails();
        clearUserDetails();
        clearAccountInput();
        setIsLoading(true);
        const results = yield (0, admin_1.getUser)({
            email: email,
            user_id: userId,
        });
        setUser(results.data.user);
        setAccounts(results.data.accounts);
        setAllAccounts(results.data.plaid_accounts);
        setErrorLogs(results.data.error_logs);
        setPlaidLogs(results.data.plaid_logs);
        setIsLoading(false);
    });
    const getAccountDetails = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        clearUserDetails();
        clearAccountsDetails();
        clearUserInput();
        setIsLoading(true);
        // Update user
        const results = yield _process(admin_1.getLatest)({
            start_date: startDate,
            end_date: endDate,
            plaid_account_id: plaidAccountId || null,
            access_token: accessToken || null,
        });
        setAccessTokenStatus(results.data);
        setPlaidAccounts(((_a = results.data) === null || _a === void 0 ? void 0 : _a.accounts) || []);
        setTransactions(((_b = results.data) === null || _b === void 0 ? void 0 : _b.transactions) || []);
        setIsLoading(false);
    });
    (0, react_1.useEffect)(() => {
        // Page title
        document.title = 'Admin - Lunch Money';
    }, []);
    const showDifference = (plaid, user) => {
        return plaid !== user ? (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "no-wrap" },
                React.createElement(semantic_ui_react_1.Icon, { name: "university", fitted: true }),
                ' ',
                plaid || React.createElement("span", { className: "color--red" }, "Missing")),
            React.createElement("p", { className: "no-wrap" },
                React.createElement(semantic_ui_react_1.Icon, { name: "user", fitted: true }),
                ' ',
                user || React.createElement("span", { className: "color--red" }, "Missing")))) : (plaid);
    };
    return (React.createElement(semantic_ui_react_1.Container, null,
        React.createElement(ContainerHeader_1.default, { title: "Admin Portal" }),
        React.createElement("div", { id: "g-admin" },
            React.createElement("div", null,
                React.createElement(semantic_ui_react_1.Card, { fluid: true },
                    React.createElement(semantic_ui_react_1.Card.Content, null,
                        React.createElement(semantic_ui_react_1.Form, null,
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null,
                                        "User Id",
                                        React.createElement(semantic_ui_react_1.Label, { circular: true, color: 'pink', empty: true, key: 'pink' })),
                                    React.createElement(EditableString_1.default, { placeholder: "Type user_id here", identifier: "user-id", firstValue: userId, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: id => {
                                            setUserId(id);
                                        } }))),
                            React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                React.createElement(semantic_ui_react_1.Form.Field, null,
                                    React.createElement("label", null,
                                        "Email",
                                        React.createElement(semantic_ui_react_1.Label, { circular: true, color: 'pink', empty: true, key: 'pink' })),
                                    React.createElement(EditableString_1.default, { placeholder: "Type email here", identifier: "email", firstValue: email, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: id => {
                                            setEmail(id);
                                        } })))),
                        (userId || email) && (React.createElement(semantic_ui_react_1.Button, { color: "orange", content: "Get user logs", icon: "right arrow", labelPosition: "right", loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield getUserDetails();
                            }) })),
                        errorLogs && (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, content: "Clear", icon: "x", labelPosition: "right", loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                clearUserDetails();
                                clearUserInput();
                            }) })))),
                [
                    'lunchbag@gmail.com',
                    'lunchmoney@lunchbag.dev',
                    'user-16504@lunchmoney.dev',
                ].indexOf(_user.email) > -1 && (React.createElement(React.Fragment, null,
                    React.createElement(semantic_ui_react_1.Card, { fluid: true },
                        React.createElement(semantic_ui_react_1.Card.Content, null,
                            React.createElement(semantic_ui_react_1.Form, null,
                                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement("label", null, "Cryptocurrency"),
                                        React.createElement(EditableString_1.default, { placeholder: "Type cryptocurrency here", identifier: "crypto", firstValue: crypto, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: id => {
                                                setCrypto(id);
                                            } })))),
                            crypto && (React.createElement(semantic_ui_react_1.Button, { color: "orange", content: "Add Crypto", icon: "right arrow", labelPosition: "right", loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    const results = yield (0, admin_1.addCrypto)({
                                        cryptocurrency: crypto,
                                    });
                                    if (results.data == -1) {
                                        _showToast({
                                            type: 'success',
                                            message: `${crypto} already exists`,
                                        });
                                    }
                                    else if (!!results.data) {
                                        _showToast({
                                            type: 'success',
                                            message: `Successfully added ${crypto} (${results.data})`,
                                        });
                                    }
                                    else {
                                        _showToast({
                                            autoDismissTimeout: 5000,
                                            type: 'error',
                                            message: `No crypto coin added`,
                                        });
                                    }
                                }) })))),
                    React.createElement(semantic_ui_react_1.Card, { fluid: true },
                        React.createElement(semantic_ui_react_1.Card.Content, null,
                            React.createElement(semantic_ui_react_1.Form, null,
                                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement("label", null, "Email Template"),
                                        React.createElement(EditableString_1.default, { identifier: "email-template", firstValue: emailTemplate, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: id => {
                                                setEmailTemplate(id);
                                            } }))),
                                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement("label", null, "User ID"),
                                        React.createElement(EditableString_1.default, { identifier: "email-user-id", firstValue: emailUserId, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: id => {
                                                setEmailUserId(id);
                                            } }))),
                                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement("label", null, "Account ID"),
                                        React.createElement(EditableString_1.default, { identifier: "email-account-id", firstValue: emailAccountId, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: id => {
                                                setEmailAccountId(id);
                                            } }))),
                                React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                                    React.createElement(semantic_ui_react_1.Form.Field, null,
                                        React.createElement("label", null, "Data"),
                                        React.createElement(semantic_ui_react_1.TextArea, { rows: 4, value: emailData, onChange: (e, { value }) => {
                                                setEmailData(value);
                                            } })))),
                            React.createElement(semantic_ui_react_1.Button, { color: "orange", content: "Send email", icon: "right arrow", labelPosition: "right", loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    const results = yield (0, admin_1.sendEmailAsAdmin)({
                                        user_id: emailUserId,
                                        template: emailTemplate,
                                        account_id: emailAccountId,
                                        data: JSON.parse(emailData),
                                    });
                                }) })))))),
            React.createElement(semantic_ui_react_1.Card, { fluid: true },
                React.createElement(semantic_ui_react_1.Card.Content, null,
                    React.createElement(semantic_ui_react_1.Form, null,
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("label", null,
                                    "Plaid Account Id",
                                    React.createElement(semantic_ui_react_1.Label, { circular: true, color: 'teal', empty: true, key: 'teal' })),
                                React.createElement(EditableString_1.default, { placeholder: "Type plaid_account_id here", identifier: "plaid-account-id", firstValue: plaidAccountId, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: id => {
                                        setPlaidAccountId(id);
                                    } }))),
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("label", null, "Access Token"),
                                React.createElement(EditableString_1.default, { placeholder: "Type access_token here", identifier: "profile-name", firstValue: accessToken, shouldSaveOnChange: true, state: 'Editing', location: 'modal', onSave: token => {
                                        setAccessToken(token);
                                    } }))),
                        React.createElement(semantic_ui_react_1.Form.Group, { widths: "equal", className: "mb-0 custom-picker" },
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("label", null, "From"),
                                React.createElement(EditableDate_1.default, { identifier: "from-query", firstValue: startDate, state: 'Editing', yearPicker: true, location: 'modal', format: 'month_day_year', onSave: start_date => {
                                        setStartDate(start_date);
                                    } })),
                            React.createElement(semantic_ui_react_1.Form.Field, null,
                                React.createElement("label", null, "To"),
                                React.createElement(EditableDate_1.default, { identifier: "to-query", firstValue: endDate, placeholder: "now", state: 'Editing', yearPicker: true, location: 'modal', format: 'month_day_year', onSave: end_date => {
                                        setEndDate(end_date);
                                    } })))),
                    (plaidAccountId || accessToken) && (React.createElement(semantic_ui_react_1.Button, { color: "orange", content: "Get Plaid Account Details", icon: "right arrow", labelPosition: "right", loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            yield getAccountDetails();
                        }) })),
                    (accessTokenStatus === null || accessTokenStatus === void 0 ? void 0 : accessTokenStatus.healthy) && (React.createElement(semantic_ui_react_1.Button, { content: "Resync", icon: "sync", labelPosition: "right", loading: isLoading, disabled: isLoading, color: "green", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setIsLoading(true);
                            // Update user
                            const results = yield _process(admin_1.pullTransactions)({
                                plaid_account_id: plaidAccountId,
                                access_token: accessToken,
                                trigger_refresh: triggerRefresh,
                                start_date: startDate,
                                end_date: endDate,
                            });
                            if (!results.error) {
                                _showToast({
                                    autoDismissTimeout: 5000,
                                    type: 'success',
                                    message: 'Successfully triggered resync. This page will auto-refresh in 5 seconds.',
                                });
                                setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                                    yield getAccountDetails();
                                }), 5000);
                            }
                            setIsLoading(false);
                        }) })),
                    (accessTokenStatus === null || accessTokenStatus === void 0 ? void 0 : accessTokenStatus.hasOwnProperty('healthy')) && (React.createElement(semantic_ui_react_1.Button, { color: "orange", basic: true, content: "Clear", icon: "x", labelPosition: "right", loading: isLoading, disabled: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            clearAccountsDetails();
                            clearAccountInput();
                        }) }))))),
        React.createElement("div", null,
            React.createElement(UserInfo_1.default, { user: user, accounts: accounts, _showToast: _showToast })),
        React.createElement("div", null,
            React.createElement(Status_1.default, { status: accessTokenStatus, _showToast: _showToast })),
        errorLogs ? (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Card.Header, { className: "flex--space-between" },
                    React.createElement("span", null),
                    React.createElement("span", null, "User Error Logs from last 14 days"),
                    React.createElement(semantic_ui_react_1.Label, { circular: true, color: 'pink', empty: true, key: 'pink' }))),
            React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" }, errorLogs.error_message ? (React.createElement("p", null, errorLogs.error_message)) : errorLogs.length == 0 ? (React.createElement("p", null, "No error logs found for this user.")) : (React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true, selectable: true },
                React.createElement(semantic_ui_react_1.Table.Header, null,
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" }, "Date"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Action"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Institution"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" }, "Event"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" }, "Error"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" }, "Error Message"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
                React.createElement(semantic_ui_react_1.Table.Body, null, plaidLogs === null || plaidLogs === void 0 ? void 0 :
                    plaidLogs.map(sessionGroup => {
                        return sessionGroup.map((error, index) => {
                            return (React.createElement(semantic_ui_react_1.Table.Row, { key: `all-error-row-${error.id}` },
                                React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" }, toReadableDateSpan(error.created_at)),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, error.action),
                                React.createElement(semantic_ui_react_1.Table.Cell, null,
                                    error.institution_name,
                                    " (",
                                    error.institution_external_id,
                                    ")"),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, error.event),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, [error.error_type, error.error_code].join(' - ')),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, error.error_message),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, error.request_id && (React.createElement(CopyCommand_1.default, { value: error.request_id, tooltipText: 'Copy request_id', _showToast: _showToast }))),
                                index == 0 && (React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap", rowSpan: sessionGroup.length }, error.link_session_id && (React.createElement(CopyCommand_1.default, { value: error.link_session_id, tooltipText: 'Copy link_session_id', _showToast: _showToast }))))));
                        });
                    }),
                    false &&
                        !errorLogs.error_message &&
                        (errorLogs === null || errorLogs === void 0 ? void 0 : errorLogs.map((error, index) => (React.createElement(semantic_ui_react_1.Table.Row, { key: `error-log-${index}` },
                            React.createElement(semantic_ui_react_1.Table.Cell, null, toReadableDateSpan(error.created_at)),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, error.action),
                            React.createElement(semantic_ui_react_1.Table.Cell, null,
                                error.institution_name,
                                " (",
                                error.institution_external_id,
                                ")"),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, error.event || error.error_type),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, error.error_code),
                            React.createElement(semantic_ui_react_1.Table.Cell, null, error.error_message),
                            React.createElement(semantic_ui_react_1.Table.Cell, { className: "no-wrap" },
                                error.link_session_id && (React.createElement(CopyCommand_1.default, { value: error.link_session_id, tooltipText: 'Copy link_session_id', _showToast: _showToast })),
                                error.request_id && (React.createElement(CopyCommand_1.default, { value: error.request_id, tooltipText: 'Copy request_id', _showToast: _showToast }))))))))))))) : (React.createElement(React.Fragment, null)),
        allAccounts ? (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Card.Header, { className: "flex--space-between" },
                    React.createElement("span", null),
                    React.createElement("span", null, "User's Accounts"),
                    React.createElement(semantic_ui_react_1.Label, { circular: true, color: 'pink', empty: true, key: 'pink' }))),
            React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" },
                React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true, selectable: true },
                    React.createElement(semantic_ui_react_1.Table.Header, null,
                        React.createElement(semantic_ui_react_1.Table.Row, null,
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" },
                                "ID",
                                React.createElement(semantic_ui_react_1.Popup, { position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "info circle" }) })),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Institution"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Name"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Mask"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Type"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Subtype"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Status"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" },
                                "Last import",
                                React.createElement(semantic_ui_react_1.Popup, { position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "info circle" }) }, "Last time transactions were imported")),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Account ID"),
                            React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
                    React.createElement(semantic_ui_react_1.Table.Body, null, allAccounts.map(accountGroup => {
                        return accountGroup
                            .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        })
                            .map((account, index) => {
                            return (React.createElement(semantic_ui_react_1.Table.Row, { key: `all-account-row-${account.id}` },
                                React.createElement(semantic_ui_react_1.Table.Cell, null, account.id),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, account.institution_name),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, showDifference(account.name, account.display_name)),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, account.mask),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, account.type),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, account.subtype),
                                React.createElement(semantic_ui_react_1.Table.Cell, null,
                                    React.createElement("span", { className: account.status == 'active'
                                            ? 'color--green'
                                            : account.status == 'inactive'
                                                ? 'color--grey'
                                                : account.status == 'relink'
                                                    ? 'color--red'
                                                    : 'color--grey' }, account.status)),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, toReadableDateSpan(account.last_import)),
                                React.createElement(semantic_ui_react_1.Table.Cell, null, account.account_id),
                                index == 0 && (React.createElement(semantic_ui_react_1.Table.Cell, { rowSpan: accountGroup.length },
                                    React.createElement(CopyCommand_1.default, { value: account.access_token, tooltipText: 'Copy access token', _showToast: _showToast })))));
                        });
                    })))))) : (React.createElement(React.Fragment, null)),
        plaidAccounts ? (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Card.Header, { className: "flex--space-between" },
                    React.createElement("span", null),
                    React.createElement("span", null, "Accounts"),
                    React.createElement(semantic_ui_react_1.Label, { circular: true, color: 'teal', empty: true, key: 'teal' }))),
            React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" }, plaidAccounts.length == 0 ? (React.createElement("p", null, "No accounts found.")) : (React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true },
                React.createElement(semantic_ui_react_1.Table.Header, null,
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" },
                            "ID",
                            React.createElement(semantic_ui_react_1.Popup, { position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "info circle" }) })),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Name"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Mask"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Type"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Subtype"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Status"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Balance"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Balance last updated"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Last fetch"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, { className: "no-wrap" },
                            "Last import",
                            React.createElement(semantic_ui_react_1.Popup, { position: "top center", trigger: React.createElement(semantic_ui_react_1.Icon, { color: "grey", className: "ml-05rem", name: "info circle" }) }, "Last time transactions were imported")),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
                React.createElement(semantic_ui_react_1.Table.Body, null, plaidAccounts.map(account => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
                    return (React.createElement(semantic_ui_react_1.Table.Row, { key: `account-row-${((_a = account.plaid) === null || _a === void 0 ? void 0 : _a.account_id) ||
                            ''}-${((_b = account.lunch_money) === null || _b === void 0 ? void 0 : _b.external_id) || ''}`, positive: ((_c = account.lunch_money) === null || _c === void 0 ? void 0 : _c.id) == parseInt(plaidAccountId) },
                        React.createElement(semantic_ui_react_1.Table.Cell, null, account.lunch_money ? (account.lunch_money.id) : (React.createElement("span", { className: "color--red" }, "Missing"))),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, showDifference((_d = account.plaid) === null || _d === void 0 ? void 0 : _d.name, (_e = account.lunch_money) === null || _e === void 0 ? void 0 : _e.name)),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, showDifference((_f = account.plaid) === null || _f === void 0 ? void 0 : _f.mask, (_g = account.lunch_money) === null || _g === void 0 ? void 0 : _g.mask)),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, showDifference((_h = account.plaid) === null || _h === void 0 ? void 0 : _h.type, (_j = account.lunch_money) === null || _j === void 0 ? void 0 : _j.type)),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, showDifference((_k = account.plaid) === null || _k === void 0 ? void 0 : _k.subtype, (_l = account.lunch_money) === null || _l === void 0 ? void 0 : _l.subtype)),
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement("span", { className: ((_m = account.lunch_money) === null || _m === void 0 ? void 0 : _m.status) == 'active'
                                    ? 'color--green'
                                    : ((_o = account.lunch_money) === null || _o === void 0 ? void 0 : _o.status) == 'inactive'
                                        ? 'color--grey'
                                        : ((_p = account.lunch_money) === null || _p === void 0 ? void 0 : _p.status) == 'relink'
                                            ? 'color--red'
                                            : 'color--grey' }, (_q = account.lunch_money) === null || _q === void 0 ? void 0 : _q.status)),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, showDifference((0, format_1.toPrice)(((_r = account.plaid) === null || _r === void 0 ? void 0 : _r.type) === 'credit'
                            ? (_s = account.plaid) === null || _s === void 0 ? void 0 : _s.balances.current
                            : ((_t = account.plaid) === null || _t === void 0 ? void 0 : _t.balances.current) ||
                                ((_u = account.plaid) === null || _u === void 0 ? void 0 : _u.balances.available), (_v = account.plaid) === null || _v === void 0 ? void 0 : _v.balances.iso_currency_code.toLowerCase()), (0, format_1.toPrice)((_w = account.lunch_money) === null || _w === void 0 ? void 0 : _w.balance, (_x = account.lunch_money) === null || _x === void 0 ? void 0 : _x.currency))),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, toReadableDateSpan((_y = account.lunch_money) === null || _y === void 0 ? void 0 : _y.balance_last_update)),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, toReadableDateSpan((_z = account.lunch_money) === null || _z === void 0 ? void 0 : _z.last_import)),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, ((_0 = account.lunch_money) === null || _0 === void 0 ? void 0 : _0.fetch_investments) == false
                            ? 'Not enabled'
                            : toReadableDateSpan((_1 = account.lunch_money) === null || _1 === void 0 ? void 0 : _1.last_import)),
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement(CopyCommand_1.default, { value: ((_2 = account.plaid) === null || _2 === void 0 ? void 0 : _2.account_id) ||
                                    ((_3 = account.lunch_money) === null || _3 === void 0 ? void 0 : _3.external_id), tooltipText: 'Copy account_id', _showToast: _showToast }))));
                }))))))) : (React.createElement(React.Fragment, null)),
        transactions && (React.createElement(semantic_ui_react_1.Card, { fluid: true, className: "mt-1rem" },
            React.createElement(semantic_ui_react_1.Card.Content, null,
                React.createElement(semantic_ui_react_1.Card.Header, { className: "flex--space-between" },
                    React.createElement("span", null),
                    React.createElement("span", null, "Transactions"),
                    React.createElement(semantic_ui_react_1.Label, { circular: true, color: 'teal', empty: true, key: 'teal' }))),
            React.createElement(semantic_ui_react_1.Card.Content, { className: "less-card-padding" }, transactions.length == 0 ? (!!accessToken ? (React.createElement("p", null, "Search by plaid_account_id to see transactions for a particular account.")) : (React.createElement("p", null,
                "No transactions found for this time period (",
                startDate,
                " -",
                ' ',
                endDate,
                ") for this account (",
                plaidAccountId,
                ")."))) : (React.createElement(semantic_ui_react_1.Table, { basic: "very", padded: true, selectable: true },
                React.createElement(semantic_ui_react_1.Table.Header, null,
                    React.createElement(semantic_ui_react_1.Table.Row, null,
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Date"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Name"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Status"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Amount"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null, "Imported on"),
                        React.createElement(semantic_ui_react_1.Table.HeaderCell, null))),
                React.createElement(semantic_ui_react_1.Table.Body, null, transactions.map(tx => {
                    var _a, _b, _c;
                    return (React.createElement(semantic_ui_react_1.Table.Row, { key: tx.transaction_id },
                        React.createElement(semantic_ui_react_1.Table.Cell, null, ((_a = tx.lunch_money) === null || _a === void 0 ? void 0 : _a.id)
                            ? showDifference(Moment(tx.date).format('MMM D, YYYY'), Moment((_b = tx.lunch_money) === null || _b === void 0 ? void 0 : _b.date).format('MMM D, YYYY'))
                            : Moment(tx.date).format('MMM D, YYYY')),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, tx.name),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, tx.pending ? 'Pending' : 'Posted'),
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement("span", { className: tx.amount < 0 ? 'color--green' : '' }, (0, format_1.toPrice)(tx.amount * -1, tx.iso_currency_code.toLowerCase()))),
                        React.createElement(semantic_ui_react_1.Table.Cell, null, toReadableDateSpan((_c = tx.lunch_money) === null || _c === void 0 ? void 0 : _c.created_at)),
                        React.createElement(semantic_ui_react_1.Table.Cell, null,
                            React.createElement(CopyCommand_1.default, { value: tx.transaction_id, tooltipText: 'Copy transaction_id', _showToast: _showToast }))));
                })))))))));
};
exports.default = Admin;
